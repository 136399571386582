import { restoreOriginalState } from '@lib/globals'

export default function(e) {
  const originalStateAttr = $('body').attr('data-original-state'),
    state = e.state

  if (!state) return
  const poppedNamespace = state.currentNamespace,
    poppedState = state.originalState
  h_console('State obj', state)
  h_console('Popped location:', document.location)
  if (originalStateAttr !== undefined) {
    let originalStateBody = JSON.parse(originalStateAttr),
      originalState = originalStateBody[poppedNamespace]
    if (originalState && originalState.selector && originalState.eventType) {
      let module = ''
      if (originalState.module) {
        module = `[data-module=${originalState.module}]`
      }
      $(`${originalState.selector}${module}`).trigger(`${originalState.eventType}`)
      restoreOriginalState(poppedNamespace)
    }
  }
}
