import { validate_input_fields, h_is_mobile } from '@lib/globals.js'
import { scrollToggleFunc } from './handleToggleScrollTop.js'
import handlePopState from './handlePopState'
import loadModule from '@lib/loadModule'

function lazyHandle(triggering_evt, original_event, original_target, module_name, pulse_args) {
  var passthrough_args = [triggering_evt, original_event, original_target]

  /* These are necessary for subsequent targeting */
  if ($(original_target).hasClass('h-triggers-operation')) {
    passthrough_args = handle_trigger_operation(original_target)
    if (!passthrough_args) {
      return false
    }
  }
  if ($(original_target).hasClass('h-triggers-state-change')) {
    passthrough_args = handle_trigger_state_change(original_target)
  }

  // See if there are multiple modules per data-module=""
  const modules = module_name.split(',')

  $.each(modules, (idx, mod_name) => {
    loadModule('lib', `handlers/${mod_name}`)
      .then((module) => {
        const lazy = module.default
        if (typeof lazy === 'function') {
          lazy(...passthrough_args)
        } else if (typeof lazy === 'object') {
          lazy.init(...passthrough_args)
        } else {
          throw 'Module: ' + module_name + ' must be a function of object that references one'
        }
        return { lazy, passthrough_args }
      })
      .then((payload) => {
        let { lazy, passthrough_args } = payload
        if (lazy.finalize) {
          lazy.finalize(...passthrough_args)
        }
        return { lazy, passthrough_args }
      })
      .then((payload) => {
        // raw-navlink means this is an actual regular link, to which we need to navigate now
        if ($(original_target).attr('href') && $(original_target).hasClass('nav-link-raw')) {
          window.location.assign($(original_target).attr('href'))
          return 'redirect'
        }
      })
      .then((redirect) => redirect || clickPulseCleanup(pulse_args))
  }) // end each
}

/**
 * This function handles all "click" events that change component state and ultimately renders
 * Because lazy loading modules is good, for every click handler, just add class="triggers-lazy-module" and data-module="moduleFileNameWithoutExtension"
 * to the node. The following code with dispath and load it automatically.
 */
export function listeners() {
  /* Prevent submitting forms on hitting enter */
  $(window)
    .keydown(function(event) {
      if (event.keyCode == 13 && $(event.target).is('input')) {
        event.preventDefault()
        return false
      }
    })
    /* Prevent dragging to window when using dropzone */
    .on('dragover', function(e) {
      e = e || event
      e.preventDefault()
    })
    .on('drop', function(e) {
      e = e || event
      e.preventDefault()
    })
    .on('scroll', scrollToggleFunc)

  window.onpopstate = handlePopState

  $(document)
    .on('ClickModuleLazyLoad', { foo: 'bar' }, (e, original_event, original_target, module_name) => {
      original_event.preventDefault()
      clickPulse(original_target).then((args) => {
        lazyHandle(e, original_event, original_target, module_name, args)
      })
    })
    .on('click', '.triggers-lazy-module', function(e) {
      e.preventDefault()
      e.stopPropagation()
      var // what was clicked
        target = $(e.currentTarget),
        // component targeted to lazy load
        lazy_module = target.attr('data-module') + '.js'

      $(document).trigger('ClickModuleLazyLoad', [e, target, lazy_module])
    })
    .on('click', '[class*="btn-"]:disabled,[class*="btn-"].disabled', function() {
      return false
    })
}

function clickPulseCleanup(args) {
  if (args.cp) {
    args.cp.remove()
  }
  if (args.node && args.node.hasClass('cp-target')) {
    args.node.removeClass('cp-target').unwrap()
  }
}

function clickPulse(element) {
  const node = $(element)
  const cp = $('<span></span>').addClass('cp')

  const prom = (done, error) => {
    if (node.hasClass('cp-disabled') || (h_is_mobile() && node.hasClass('cp-disabled-mobile'))) {
      done({})
      return false
    }

    node
      .addClass('cp-target')
      .wrap('<div>')
      .parent()
      .addClass('cp-wrap')
      .prepend(cp)

    function handleAnimationEnd(event) {
      event.stopPropagation()
      done({ node: node, cp: cp })
    }

    // Fallback in case animation doesn't trigger, we resolve the promise
    setTimeout(() => {
      if ($('.cp').length > 0) {
        done({ node: node, cp: cp })
      }
    }, 1500)

    cp[0].addEventListener('animationend', handleAnimationEnd, { once: true })
  }
  return new Promise(prom)
}

function handle_trigger_operation(target) {
  var /*
        All inputs geared for sending to ajax must have the following:
        1) an operations class that handles the ajax callback
        2) a magic class on the input element named 'filtered-input'
        3) a 'data-unfiltered-key' attribute on the input element that matches the FilteredInput defined variable (also available in local_obj)
        4) 'h-triggers-operation' class on the triggering submit element
        5) 'data-container' attrubite on the triggering submit element encapsulating the fields to target
      */
    target = $(target)
  if (validate_input_fields(target.parents('form'), target)) {
    return target
  }
  return false
}

function handle_trigger_state_change(target) {
  var // what was clicked
    target = $(target),
    // component targeted to change state, i.e. "#updateGrid"
    component = target.attr('data-target'),
    // Callback if provided
    callback = target.attr('data-callback')

  return [target, component, callback]
}
