import UploadFiles from '@lib/upload-dropzone.js'
import { validate_input_fields, loadLocalizedData, rest_entry_point } from '@lib/globals.js'
import Awesomplete from 'awesomplete'
import Dropzone from 'dropzone'
import * as tinymce_editor from '@lib/tinymce-editor'
import loadModule from '@lib/loadModule'

export default function () {
  h_console('insert')
  init_insert()
}

function init_insert() {
  Dropzone.autoDiscover = false

  loadModule('tab')

  tinymce_editor.render()

  var isInsert = $('input[name="id"]').length < 1

  // No submit until updates are made
  if (isInsert) {
    // Set defaults
    $('#artwork-crud-work,#show-crud-work')
      .prop('checked', true)
      .removeClass('clean')
      .addClass('dirty filtered-input')
    $('#workCategoryFilterCount > span.count').text(
      $('#crud-work-category-editor > span:not(.d-none)').length
    )
  } else {
    $('.h-triggers-operation').not('[name="save-as-work-submit"]').prop('disabled', true)
  }

  // Dropzone
  window.dropzoneInstance = {}
  $('[id^="DZ-"]').each(function (idx, dz) {
    var uploadObj = new UploadFiles(dz),
      form_id = $(dz).parents('form').attr('id')
    window.dropzoneInstance[form_id] = uploadObj
  })

  // Show the coorect type of view based on product category
  toggle_view_for_product_cat()

  // Artist autocomplete
  var artist_input = document.getElementById('artist-crud-work')

  if (artist_input) {
    new Awesomplete(artist_input, {
      list: local_obj.artists,
      data: function (item, input) {
        return { label: item.post_title, value: item.id }
      },
      // insert label instead of value into the input.
      replace: function (suggestion) {
        this.input.value = suggestion.label
      },
    })
    artist_input.addEventListener('awesomplete-selectcomplete', function (e) {
      var artist_id = e.text.value,
        hidden =
          '<input type="hidden" id="artist-hidden" class="filtered-input dirty" data-unfiltered-key="' +
          _INPUT_CRUD_ARTIST_ID +
          '" value="' +
          artist_id +
          '">',
        hidden_elem = $(hidden)
      $('#artist-hidden').remove()
      $(artist_input).after(hidden_elem)
    })
  }

  // Media autocomplete
  var media_input = $('#media-crud-work')[0]

  if (media_input) {
    new Awesomplete(media_input, {
      maxItems: 30,
      list: local_obj.media,
      data: function (item, input) {
        return { label: item.label, value: item.label }
      },
    })
  }

  // Work categories autocomplete
  var wc_inputs = $('#work-categories-crud-work,#work-categories-crud-exhibition'),
    sync_hidden = function (form_group) {
      var slugs = form_group
          .find('.selected-cat')
          .get()
          .map((el) => $(el).attr('data-slug')),
        queued = form_group
          .find('.queued-cat')
          .get()
          .map((el) => $(el).attr('data-name'))

      /** If any slugs are present - new or existing - set to dirty because we set terms with differences removed **/
      form_group
        .find('.wc-selected-slugs')
        .val(slugs.join(','))
        .toggleClass('filtered-input dirty', slugs.length > 0)
        .toggleClass('clean', slugs.length < 1)

      form_group
        .find('.wc-created-slugs')
        .val(queued.join(','))
        .toggleClass('filtered-input dirty', queued.length > 0)
        .toggleClass('clean', queued.length < 1)

      control_submit(form_group.parents('form'))
    },
    create_cat_button = function (label, slug, btn_class) {
      var slug_bit = '',
        truncate = (str) => {
          const position = 20,
            len = str.length,
            cut = str.substring(0, position)
          if (position < len) {
            return cut + ' ...'
          }
          return cut
        },
        label_truncated = truncate(label)

      if (slug) {
        slug_bit = 'data-slug="' + slug + '"'
      }

      return `
        <span title="${label}" data-name="${label}" ${slug_bit} class="btn btn-outline-dark ${btn_class}">
          <span>${label_truncated}</span>
          <i class="fa-solid fa-times icon ml-2"></i>
        </span>
      `
    },
    init_category_buttons = function (form_group) {
      var hidden = form_group.find('.wc-selected-slugs'),
        existing_names = hidden.attr('data-name'),
        existing_slugs = hidden.attr('data-original-value'),
        names_arr = existing_names.split(','),
        slugs_arr = existing_slugs.split(','),
        wc_input = form_group.find('[id*="work-categories"]')

      $('.selected-cat,.queued-cat').remove()

      $.each(names_arr, function (idx, name) {
        if (name.trim() == '') return
        var btn = create_cat_button(names_arr[idx], slugs_arr[idx], 'selected-cat')
        form_group.find('.wc-add-new').before(btn)
      })
      hidden.val(existing_slugs)
      hidden.attr('data-name', existing_names)
      $(wc_input).val('')
    }

  // Init category buttons and create/attach Awesomplete to the t
  $.each(wc_inputs, function (idx, wc_input) {
    var form_group = $(wc_input).parents('.form-group')

    // In case of update, hidden labels and slugs will be pre-populated in the hidden input
    // Pre-create all the buttons
    init_category_buttons(form_group)

    // Instantiate autocomplete
    new Awesomplete($(wc_input)[0], {
      maxItems: 30,
      list: local_obj.work_categories.filter((c) => c.status !== _TERM_DEPRECATED),
      replace: function (text) {
        this.input.value = ''
      },
    })

    // Add listeners specific to the work category input's and its form group's scope

    wc_input.addEventListener('awesomplete-selectcomplete', function (e) {
      var slug = e.text.value,
        label = e.text.label,
        new_cats = [],
        btn = create_cat_button(label, slug, 'selected-cat')

      // Don't add it if it exists
      if (form_group.find('.selected-cat[data-slug="' + slug + '"]').length) {
        $('.undo').addClass('invisible')
        return
      }

      // Add button cat
      form_group.find('.wc-add-new').before(btn)

      // Track hidden slugs selected
      sync_hidden(form_group)
    })

    // Remove a selected or queued cat
    form_group.on('click', '.selected-cat, .queued-cat', function (e) {
      $(e.currentTarget).remove()
      sync_hidden(form_group)
      // This triggers evaluating submit button state and dirty/undo states
      form_group.find('input').trigger('input')
    })

    // Add new cat
    form_group
      .on('click', '.add-cat', function (e) {
        var clicked_add_new = $(e.currentTarget),
          temp_input = clicked_add_new.siblings('.input-group')

        clicked_add_new.addClass('d-none')
        temp_input.removeClass('d-none')
      })
      .on('keydown', 'input[name="insert_new_wc_helper"]', function (e) {
        if (e.key === 'Enter' || e.keyCode == 13) {
          form_group.find('.add-cat-save').trigger('click')
        }
        return true
      })
      .on('click', '.add-cat-save,.add-cat-cancel', function (e) {
        var clicked = $(e.currentTarget),
          wc_add_new = clicked.parents('.wc-add-new'),
          add_new_btn = wc_add_new.find('.add-cat'),
          input_group = wc_add_new.find('.input-group'),
          temp_input = wc_add_new.find('input[name="insert_new_wc_helper"]'),
          label = temp_input.val().trim()

        if (!label || clicked.is('.add-cat-cancel')) {
          add_new_btn.removeClass('d-none')
          input_group.addClass('d-none')
          temp_input.val('')
          return false
        }

        // Oops does it already exist?
        let regex = new RegExp('^' + label + '$', 'i'),
          found = local_obj.work_categories.find((cat) => cat.label.match(regex) || cat.value.match(regex)),
          btn = null

        if (found) {
          let slug = found.value

          btn = create_cat_button(found.label, slug, 'selected-cat')

          // Don't add it if it was already selected - an odd condition but preventitive
          if (form_group.find('.selected-cat[data-slug="' + slug + '"]').length) {
            $('.undo').addClass('invisible')
            return
          }
        } else {
          btn = create_cat_button(label, null, 'queued-cat')
        }

        // Add the cat button
        wc_add_new.before(btn)
        temp_input.val('')
        add_new_btn.removeClass('d-none')
        input_group.addClass('d-none')
        sync_hidden(form_group)
      })
  })

  /* Sync Artist Name */
  $('#first-name-crud-artist,#last-name-crud-artist').on('input', function (e) {
    var full_name = $('#full-name-as-it-will-appear-crud-artist'),
      first_last = [],
      last_name = $('#last-name-crud-artist').val(),
      first_name = $('#first-name-crud-artist').val(),
      original_value = full_name.attr('data-original-value')

    if (first_name) {
      first_last.push(first_name.trim())
    }
    if (last_name) {
      first_last.push(last_name.trim())
    }
    full_name.val(first_last.join(' '))
    if (full_name.val() == original_value) {
      full_name.addClass('clean').removeClass('dirty filtered-input')
    } else {
      full_name.addClass('dirty filtered-input').removeClass('clean')
    }
  })

  $(document)
    .on('show.bs.tab', '#insert-work-category-tab', function (e) {
      render_work_category_management_grid()
    })
    .on('awesomplete-open', '.awesomplete', function (e) {
      let target = $(e.currentTarget),
        form_group = target.parents('.form-group')
      $('body').addClass('awesomplete-active')
      form_group.addClass('awesomplete-active')
    })
    .on('awesomplete-close', '.awesomplete', function (e) {
      $('.awesomplete-active').removeClass('awesomplete-active')
    })
    /* Show and Hide Elements based on Product Cat */
    .on('input', 'input[name="product_cat"]', function (e) {
      toggle_view_for_product_cat(e)
    })
    .on('click', '.h-login', function (e) {
      var target = $(e.currentTarget)
      return validate_input_fields(target.parents('form'), target)
    })
    /* Reset */
    .on('click', '.reset-form', function (e) {
      e.preventDefault()
      e.stopPropagation()
      window.location.reload()
    })
    /* Track Edits */
    .on('input', 'form .filtered-input,form .clean,form .dirty,form .undo', function (e) {
      var target = e.currentTarget
      trackEdits(target)
    })
    /* Filter WC statuses */
    .on('input', 'input[name="work_category_status"]', function (e) {
      var show_these = $('input[name="work_category_status"]:checked')
        .get()
        .map((checkbox) => parseInt($(checkbox).val()))
      $('#crud-work-category-editor > *').each((i, cat) => {
        let status = $(cat).data('meta')
        $(cat).toggleClass('d-none', !show_these.includes(status))
      })
      $('#workCategoryFilterCount > span.count').text(
        $('#crud-work-category-editor > span:not(.d-none)').length
      )
    })
    // Search box for worc cats under WC Management
    .on('input', '#searchWorkCategory', function (e) {
      var the_input = $(e.currentTarget),
        typed_val = the_input.val().trim()
      if (typed_val.length < 1) {
        $('#root-crud-work-category').trigger('input')
        return false
      }
      if (typed_val.length < 3) return false

      $('#crud-work-category-editor > *').each((i, cat) => {
        let category = $(cat).find('.term-name').html(),
          regex = new RegExp(`${typed_val}`, 'gi')
        $(cat).toggleClass('d-none', !category.match(regex))
      })
      $('#workCategoryFilterCount > span.count').text(
        $('#crud-work-category-editor > span:not(.d-none)').length
      )
    })
    .on('click', '#crud-work-category-editor > span', function (e) {
      var clicked = $(e.currentTarget),
        current_value = clicked.attr('data-name'),
        current_status = clicked.data('meta'),
        options = []

      _TERM_STATUS_TYPES.forEach((statusObj, i) => {
        let selected = ''
        if (statusObj.status === current_status) {
          selected = 'selected '
        }
        options.push(`<option ${selected}value="${statusObj.status}">${statusObj.name}</option>`)
      })

      for (var work_status in local_obj.work_category_statuses) {
      }

      let theInput = `
          <input type="text" class="form-control" name="wcTempInput" value="${current_value}">
          <select class="work-status-select custom-select" id="updateWorkStatus" style="flex: 0 1 12em">
            <option value="-1" selected>Unassigned Status</option>
            ${options.join('')}
          </select>
          <div class="input-group-append"><button style="min-width: 5em;" class="btn btn-success h-save" type="button">Save</button></div>
          <div class="input-group-append"><button style="min-width: 5em;" class="btn btn-outline-secondary h-cancel" type="button">Cancel</button></div>
          `
      clicked
        .addClass('d-none hidden-work-category')
        .before(
          '<div id="wcTempInput" class="neumo neumo-lg input-group temp-input animate__animated animate__fadeIn">'
        )

      $('#crud-work-category-editor').addClass('edit-active')
      $('#wcTempInput').html(theInput)
    })
    .on('click', '#wcTempInput .h-save', function (e) {
      let data_node = $('.hidden-work-category'),
        temp_input = $('#wcTempInput'),
        term_id = data_node.data('term-id'),
        original_name = data_node.attr('data-name'),
        original_status = data_node.data('meta'),
        edited_name = temp_input.find('input[name="wcTempInput"]').val().trim(),
        edited_status = $('#updateWorkStatus').find('option:selected').val()

      const request_data = {}
      request_data[_INPUT_ID] = term_id

      let edit_made = false
      if (edited_name !== original_name) {
        request_data[_INPUT_CRUD_WORK_CATEGORY] = edited_name
        edit_made = true
      }
      if (parseInt(edited_status) != original_status) {
        request_data[_INPUT_WORK_CATEGORY_STATUS] = edited_status
        edit_made = true
      }

      let operation = 'WorkCategoryManagement',
        route = local_obj.route_map[operation]['route'],
        method = local_obj.route_map[operation]['method']
      if (edit_made) {
        rest_entry_point({
          route: route,
          request_data: request_data,
          method: method,
          cache: false,
        })
          .then((body) => {
            data_node
              .attr('data-name', edited_name)
              .data('meta', edited_status)
              .find('.term-name')
              .text(edited_name)
            $('#wcTempInput .h-cancel').trigger('click')
            return body
          })
          .then((save_results) => {
            // Reload local data
            loadLocalizedData()
              .then((localized_result) => {
                render_work_category_management_grid()
                h_alert({ msg: save_results.msg, alert_color: 'text-success' })
              })
              .catch((error) => {
                h_alert(JSON.stringify(error))
              })
          })
          .catch((error) => {
            h_alert(JSON.stringify(error))
          })
      } else {
        // Close everything
        $('#wcTempInput .h-cancel').trigger('click')
      }
    })
    .on('click', '#wcTempInput .h-cancel', function (e) {
      $('#wcTempInput').remove()
      $('.hidden-work-category').removeClass('d-none hidden-work-category')
      $('#crud-work-category-editor').removeClass('edit-active')
    })
    // Reset the above
    .on('click', '.h-reset-work-categories', function (e) {
      $('#crud-work-category-editor > .d-none').removeClass('d-none')
      $('#searchWorkCategory').val('')
      $('#root-crud-work-category').trigger('input')
    })
    // Undo
    .on('click', '.undo', function (e) {
      var undo = $(this),
        form_group = undo.parents('.form-group'),
        sibling_input = form_group.find('.dirty'),
        is_radio_or_check = sibling_input.first().is('[type="radio"],[type="checkbox"]'),
        original_val = is_radio_or_check
          ? sibling_input.first().attr('data-original-value')
          : sibling_input.attr('data-original-value')

      if (sibling_input.attr('data-unfiltered-key') == 'work-category') {
        init_category_buttons(form_group)
        $('[class*="-slugs"]').add(sibling_input).removeClass('filtered-input dirty').addClass('clean')
      } else if (sibling_input.attr('data-unfiltered-key') == 'type-of-work') {
        sibling_input.filter('[value="' + original_val + '"]').prop('checked', true)
      } else if (is_radio_or_check) {
        // find the original value's id
        var target_radio = sibling_input.get().filter((el) => original_val == el.value)
        $(target_radio).prop('checked', true).trigger('input')
      } else {
        sibling_input.val(original_val)
      }

      sibling_input.removeClass('filtered-input dirty').addClass('clean')
      $('#first-name-crud-artist,#last-name-crud-artist').trigger('input')

      // Hide and avoid submit if nothing dirty
      control_submit(undo.addClass('invisible').parents('form'))
    })
}

function toggle_view_for_product_cat(e) {
  let target = this || $('.form-group-InputRadioProductCategory input:checked')

  if (target && target.length < 1) {
    return // Must be at initial insert
  }

  var author = $('#author-name-crud-work'),
    publication_whitelist_fields =
      '.form-group-InputRadioProductCategory, .form-group-Dropzone, .form-group-InputTextPublicationAuthor, .form-group-InputTextPostTitle, .form-group-InputTextAreaDescription, .form-group-InputTextNumberPrice, .form-group-InputRadioStockStatus, .form-group-button-crud-exhibition, .form-group-button-crud-work, .form-group-button-crud-artist',
    parent_form = target.parents('form'),
    disabled_when_pub = parent_form.find('.form-group').not($(publication_whitelist_fields)),
    featured_img_label = parent_form.find('.edit-featured').siblings('label'),
    save_featured_img_label = featured_img_label.attr('data-save-label') || featured_img_label.html(),
    dropzone_message = parent_form.find('.dz-button'),
    save_dropzone_message = dropzone_message.attr('data-save-label') || dropzone_message.html()

  if (!featured_img_label.attr('data-save-label')) {
    featured_img_label.attr('data-save-label', save_featured_img_label)
  }
  if (!dropzone_message.attr('data-save-label')) {
    dropzone_message.attr('data-save-label', save_dropzone_message)
  }

  author.parents('.form-group').find('.invalid-feedback').remove()

  if (target.is('#artwork-crud-work')) {
    disabled_when_pub.removeClass('d-none')
    author.attr('required', false).parents('.form-group').addClass('d-none')
    featured_img_label.html(save_featured_img_label)
    dropzone_message.html(save_dropzone_message)
    dropzoneInstance['crud-work'].myDropzone.options.acceptedFiles = 'image/jpeg'
  } else {
    disabled_when_pub.addClass('d-none')
    author
      .attr('required', 'required')
      .after('<div class="invalid-feedback">This field is required</div>')
      .parents('.form-group')
      .removeClass('d-none')
    featured_img_label.html('Featured Image and/or Downloadable File:')
    dropzone_message.html(save_dropzone_message.replace('Drop image', 'Drop image and/or downloadable files'))
    dropzoneInstance['crud-work'].myDropzone.options.acceptedFiles = 'image/jpeg,image/png,application/pdf'
  }
}

function trackEdits(target) {
  var current_input = $(target),
    is_radio_or_check = current_input.is('[type="radio"],[type="checkbox"]'),
    current_value = current_input.val(),
    original_value = current_input.attr('data-original-value'),
    candidates_for_update = $(
      'form.needs-validation .filtered-input:not(#artist-hidden):not(.dirty):not(.btn):not(input[name="id"]):not(input[type="hidden"])'
    ),
    undo_btn = current_input.parents('.form-group').find('.undo')

  // First time in, everything is clean
  candidates_for_update.removeClass('filtered-input').addClass('clean')

  // Treat as group
  if (is_radio_or_check) {
    current_input = $(target).parents('.form-group').find('input')
  }

  // Its been dirtied
  current_input.each(function (idx, the_input) {
    $(the_input).addClass('dirty filtered-input').removeClass('clean')
  })

  // Dont continue to track for insert
  if ($('input[name="id"]').length < 1) {
    // means its insert
    return
  }

  undo_btn.removeClass('invisible')

  if (is_radio_or_check) {
    var checkedInput = $('input[name="' + current_input.attr('name') + '"]:checked')
    current_value = checkedInput.val()
    original_value = checkedInput.attr('data-original-value')
  }

  // But if it's been reverted to original make it clean again
  if (current_value === original_value) {
    current_input.each(function (idx, the_input) {
      $(the_input).addClass('clean').removeClass('filtered-input dirty')
    })
    undo_btn.addClass('invisible')
  }

  // Avoid submit if nothing dirty
  control_submit(current_input.parents('form'))
}

export function render_work_category_management_grid() {
  const df = new DocumentFragment()

  local_obj.work_categories.forEach((record, i) => {
    let classes = ['btn']
    switch (parseInt(record.status)) {
      case _TERM_ROOT:
        classes.push('btn-outline-success')
        break
      case _TERM_ARCHIVE:
        classes.push('btn-outline-secondary')
        break
      case _TERM_DEPRECATED:
        classes.push('btn-outline-danger')
        break
      default:
        classes.push('btn-outline-secondary')
    }

    let wc_class = classes.join(' '),
      outer_span = $('<span>'),
      inner_span = $('<span>'),
      sup = $('<sup>')

    outer_span.attr({
      'class': wc_class,
      'data-slug': record.value,
      'data-term-id': record.id,
      'data-name': record.label,
      'data-meta': record.status,
      'data-count': record.count,
    })

    inner_span.addClass('term-name').text(record.label)
    sup.addClass('term-count').text(record.count)

    outer_span.append(inner_span).append(sup)

    df.appendChild(outer_span.get(0))
  })

  $('#crud-work-category-editor').html(df.cloneNode(true))
  $('#workCategoryFilterCount > span.count').text(df.childElementCount)
}

export function control_submit(enclosing_element) {
  $(enclosing_element)
    .find('.h-triggers-operation')
    .not('[name="save-as-work-submit"]')
    .prop('disabled', $('.dirty').length < 1)
}
