/** Common Bootstrap **/

h_console('vendor')

/** Common fontawesome consider breaking up **/

import { library, dom, config as faConfig } from '@fortawesome/fontawesome-svg-core'

import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons/faArrowsAltH'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons/faArrowsAltV'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight'
import { faRulerCombined } from '@fortawesome/free-solid-svg-icons/faRulerCombined'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt'

import { faStackpath } from '@fortawesome/free-brands-svg-icons/faStackpath'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'

import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf'
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar'

library.add(
  faArrowsAltH,
  faArrowsAltV,
  faArrowLeft,
  faArrowUp,
  faAngleRight,
  faBookOpen,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faDownload,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExpand,
  faFilePdf,
  faFilter,
  faInfoCircle,
  faLink,
  faMapMarkerAlt,
  faPhone,
  faPrint,
  faQuoteRight,
  faRulerCombined,
  faSearch,
  faShoppingCart,
  faSlidersH,
  fasStar,
  faStar,
  faTimes,
  faTimesCircle,
  faUndoAlt,
  faUpload,
  faStackpath,
  faTwitter,
  faFacebook,
  faPinterest,
  faInstagram
)
faConfig.searchPseudoElements = false
// dom.watch();

var faDom = dom

export { faConfig, faDom }
