import { h_console } from '@lib/globals.js'

const scrollToTopButton = document.getElementById('js-top')

export const scrollToggleFunc = () => {
  // Get the current scroll value
  let y = window.scrollY

  // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
  $(scrollToTopButton)
    .toggleClass('show', y > 0)
    .toggleClass('hide', y <= 0)
}

const goToTop = () => {
  // Let's set a variable for the number of pixels we are from the top of the document.
  const c = document.documentElement.scrollTop || document.body.scrollTop

  // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
  // We'll also animate that scroll with requestAnimationFrame:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  if (c > 0) {
    window.requestAnimationFrame(goToTop)
    // ScrollTo takes an x and a y coordinate.
    // Increase the '10' value to get a smoother/slower scroll!
    window.scrollTo(0, c - c / 10)
  }
}

const init = () => {
  goToTop()
}

const finalize = (triggering_evt, original_event, original_target) => {}

export default {
  init,
  finalize,
}
