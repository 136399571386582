import lady from '@src/images/lady-scale.png'
import man from '@src/images/man-scale.png'
import { addImageProcess } from '@lib/globals'
export default class HumanScale {
  constructor(options) {
    let { widthInches, heightInches, src, typeOfWork, isSmallMedia } = options
    this.widthInches = widthInches
    this.heightInches = heightInches
    this.src = src
    this.typeOfWork = typeOfWork
    this.screenIsLandscape = screen.width > screen.height
    this.artIsLandscape = this.widthInches > this.heightInches
    this.isSmallMedia = isSmallMedia
    this.initCanvas()
  }
  initCanvas() {
    const canvasWidthPixels = this.isSmallMedia ? screen.width : screen.width * 0.75,
      ceilingHeightBaselineInches = (() => {
        // Characteristics of artwork determines ceiling height
        const ideal = () => {
          const comfortableTall = 1.618
          let howCloseToIdealCeiling = (12 * 9.5) / this.heightInches
          if (howCloseToIdealCeiling < comfortableTall) {
            return comfortableTall * this.heightInches
          }
          return 108
        }
        var minCeilingHeight = 108,
          idealCeilingHeight = ideal(),
          maxCeilingHeight = 12 * 13
        return this.clamp(minCeilingHeight, idealCeilingHeight, maxCeilingHeight)
      })(),
      ceilingHeightPixels = canvasWidthPixels / (this.screenIsLandscape ? 1.382 : this.artIsLandscape ? 0.85 : 0.75)

    // Half the width of the canvas is a good ratio
    this.ratio = ceilingHeightPixels / ceilingHeightBaselineInches

    let canvas = document.createElement('canvas')
    canvas.id = 'HumanScale'
    canvas.width = canvasWidthPixels
    canvas.height = ceilingHeightPixels

    this.canvas = canvas
  }
  toInches(dimsPixels) {
    let { w, h } = dimsPixels
    w = w || 0
    h = h || 0
    let widthInches = w / this.ratio,
      heightInches = h / this.ratio
    return { widthInches, heightInches }
  }
  toPixels(dimsInches) {
    let { w, h } = dimsInches
    w = w || 0
    h = h || 0
    let widthPixels = w * this.ratio,
      heightPixels = h * this.ratio
    return { widthPixels, heightPixels }
  }
  clamp(min, val, max) {
    return Math.max(min, Math.min(val, max))
  }
  shadow(ctx, options) {
    options = options || {}
    let { off, offsetX, offsetY, shadowBlur, shadowColor } = options
    if (off === true) {
      ctx.shadowColor = 'rgba(0,0,0,0)'
      return
    }
    offsetX = offsetX || 4
    offsetY = offsetY || 4
    ctx.shadowColor = shadowColor || 'rgba(171,181,191,1)'
    ctx.shadowOffsetX = offsetX
    ctx.shadowOffsetY = offsetY
    ctx.shadowBlur = shadowBlur || 1.5 * ((Math.abs(offsetX) + Math.abs(offsetY)) / 2)
  }

  async draw() {
    const images = await addImageProcess([this.src, lady, man])

    let ctx

    if (this.canvas.getContext) {
      ctx = this.canvas.getContext('2d')
    } else {
      throw new Error('Canvas not supported')
    }

    let artworkInPixels = this.toPixels({ w: this.widthInches, h: this.heightInches }),
      x = this.canvas.width / 2 - artworkInPixels.widthPixels / 2,
      frameThickness = 0,
      wallMargin = (this.canvas.width - artworkInPixels.widthPixels) / 2,
      yEyeHeight = this.toPixels({ h: 62 }),
      y = this.canvas.height - yEyeHeight.heightPixels - artworkInPixels.heightPixels / 2,
      ladyInPixels = this.toPixels({ w: 18.3125, h: 68 }),
      manInPixels = this.toPixels({ w: 21, h: 72 }),
      isWorksOnPaper = this.typeOfWork.match(/(print|watercolor|photograph|pastel|ink wash|drawing)/i)

    // Artwork border outer
    let addMat = (this.widthInches < 18 || this.heightInches < 18) && isWorksOnPaper

    if (addMat) {
      let matThickness = (Math.min(artworkInPixels.widthPixels, artworkInPixels.heightPixels) / 1.618) * 2

      // First the outer frame
      frameThickness = matThickness + (this.isSmallMedia ? 6 : 12)
      wallMargin -= (frameThickness + matThickness) / 2

      // Add shadow to outer frame
      this.shadow(ctx)

      // Draw the outer frame
      ctx.beginPath()
      ctx.strokeStyle = '#000' // some color/style
      ctx.lineWidth = frameThickness // thickness
      ctx.strokeRect(x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)
      ctx.closePath()

      // Turn off shadow
      this.shadow(ctx, { off: true })

      // Draw the Mat
      ctx.beginPath()
      ctx.strokeStyle = '#fff' // some color/style
      ctx.lineWidth = matThickness // thickness
      ctx.strokeRect(x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)
      ctx.closePath()
    } else {
      // Add shadow to outer frame
      this.shadow(ctx)

      ctx.beginPath()
      ctx.strokeStyle = '#ffffff' // some color/style
      ctx.lineWidth = this.isSmallMedia ? 12 : 24 // thickness
      ctx.strokeRect(x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)
      ctx.closePath()

      // Turn off shadow
      this.shadow(ctx, { off: true })
    }

    // Artwork border inner - double for works on paper
    ctx.beginPath()
    ctx.strokeStyle = isWorksOnPaper ? '#d0d0d0' : '#303030' // 'rgba(0,0,0,0)'; // some color/style
    ctx.lineWidth = isWorksOnPaper ? 4 : 8 // thickness
    ctx.strokeRect(x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)
    ctx.closePath()

    if (isWorksOnPaper) {
      ctx.beginPath()
      ctx.strokeStyle = '#fff'
      ctx.lineWidth = 2 // thickness
      ctx.strokeRect(x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)
      ctx.closePath()
    }

    // Artwork
    ctx.drawImage(images[0].img, x, y, artworkInPixels.widthPixels, artworkInPixels.heightPixels)

    if (!this.isSmallMedia) {
      this.shadow(ctx, {
        offsetX: 50,
        offsetY: manInPixels.heightPixels * 0.1,
        shadowBlur: 30,
        shadowColor: 'rgba(171,181,191,.5)',
      })
    }

    // Lady on the right is margin + (frame * 2) + artwork + (margin / 2) + (ladywidth / 2)
    let xLady = [
        wallMargin,
        frameThickness * 2,
        artworkInPixels.widthPixels,
        wallMargin / 2,
        ladyInPixels.widthPixels / -2,
      ],
      yLady = this.canvas.height - ladyInPixels.heightPixels
    xLady = xLady.reduce((carry, current) => current + carry, 0)
    ctx.drawImage(images[1].img, xLady, yLady, ladyInPixels.widthPixels, ladyInPixels.heightPixels)

    if (!this.isSmallMedia) {
      this.shadow(ctx, {
        offsetX: -50,
        offsetY: ladyInPixels.heightPixels * 0.1,
        shadowBlur: 30,
        shadowColor: 'rgba(171,181,191,.5)',
      })
    }

    // Man on the left is (leftmargin - manwidth) / 2
    let xMan = (wallMargin - manInPixels.widthPixels) / 2,
      yMan = this.canvas.height - manInPixels.heightPixels
    ctx.drawImage(images[2].img, xMan, yMan, manInPixels.widthPixels, manInPixels.heightPixels)

    return images
  }
}
