export default function () {
  h_console('presentation')
  init_presentation()
}

function init_presentation() {
  h_console('presentation')
  $(document)
    .on('click', '.download-print .print-info-sheet', function (e) {
      window.print()
    })
    .on('click', '.download-pdf', function (e) {
      var btn = $(e.currentTarget),
        save_html = btn.html(),
        cookieTimer = null,
        deleteCookie = function () {
          document.cookie = '_h_downloaded=0' + '; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
        }

      function checkCookies() {
        var downloaded =
          document.cookie.replace(/(?:(?:^|.*;\s*)_h_downloaded\s*\=\s*([^;]*).*$)|^.*$/, '$1') == '1'
        if (downloaded) {
          deleteCookie()
          clearInterval(cookieTimer)
          btn.html(save_html)
          return true
        }
      }

      btn.html('<i class="fa-solid fa-circle-notch icon fa-spin mr-2"></i>Downloading')
      deleteCookie()

      cookieTimer = setInterval(checkCookies, 500)
    })
    .on('click', '#printBio', function (e) {
      var check = $(e.currentTarget),
        href = $('.download-pdf').attr('href'),
        re = /print_bio=.+?(?=&|$)/
      $('#presentBio').toggleClass('d-none', !check.is(':checked'))
      $('.download-pdf').attr('href', href.replace(re, 'print_bio=' + check.is(':checked').toString()))
    })
}
