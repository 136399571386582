import waitForScroll from '@lib/waitForScroll'

export function readmore_helper() {
  ;(function init() {
    require('@node/readmore-js')

    const readmoreElem = $('.h-readmore')
    if (!readmoreElem) return

    var lineHeight = readmoreElem.css('line-height') || '24'
    if (isNaN(lineHeight.replace('px', ''))) {
      readmoreElem.find('*').each((idx, itm) => {
        var css = $(itm).css('line-height').replace('px', '')
        if (!isNaN(css)) {
          lineHeight = css
          return false
        }
      })
    }

    lineHeight = !isNaN(lineHeight) ? lineHeight : '24'

    $('.h-readmore').readmore({
      collapsedHeight: 10 * parseFloat(lineHeight),
      speed: 1000,
      moreLink: '<a aria-label="Read more" class="pt-3" href="test">More ...</a>',
      lessLink: '<a aria-label="Read less" class="pt-3" href="test">Less</a>',
      beforeToggle: async function (button, readMoreElementContents, expanded) {
        let el = readMoreElementContents.get(0),
          scrollTo = el.closest('.read-more-scroll') || $(el).prev('.read-more-scroll').get(0)
        await waitForScroll(window, expanded ? 0 : scrollTo, { block: 'start' })
      },
    })
  })()
}
