import { log_google_analytics } from '@lib/globals'
import GA from '@lib/ga'
import loadModule from '@lib/loadModule'
import HumanScale from '@lib/HumanScale'

export default function(triggering_evt, original_event, original_target) {
  var clicked = $(original_target),
    modal = $('#h-human-scale'),
    parent = original_target.parents('[id*=workDetail]'),
    img = parent.find('img'),
    src = img.attr('src'),
    alt = img.attr('alt'),
    post_id = img.attr('data-post-id'),
    actualDims = parent.find('.work-dimensions > .fraction').get(),
    heightInches = $(actualDims[0]).attr('data-inches'),
    widthInches = $(actualDims[1]).attr('data-inches'),
    mediaQuery = window.matchMedia('(min-width: 576px)'),
    isSmallMedia = !!!mediaQuery.matches,
    typeOfWork = clicked.attr('data-type-of-work')

  let options = { widthInches, heightInches, src, typeOfWork, isSmallMedia }

  const human = new HumanScale(options)
  let canvas = human.canvas

  log_google_analytics(original_target, function(element) {
    return GA.dispatch('humanScale', alt, post_id)
  })

  loadModule('modal').then(async (module) => {
    modal.find('#humanScaleCanvasWrap').html(canvas)
    let saveButton = clicked.html()
    clicked.html('Please wait ...').addClass('disabled')
    await human.draw()
    modal.modal('show')
    clicked.html(saveButton).removeClass('disabled')
  })
}
