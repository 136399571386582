import { listenCookieChange, log_google_analytics, observeRendering } from '@lib/globals'
import GA from '@lib/ga'

export default function () {
  h_console('cart')

  $('#ship-to-different-address-checkbox').trigger('click')

  $(document).on('click', '.product-remove > .remove', handle_remove_from_cart)

  // Ugly, ugly hack to get taxes to update when changing state

  let billing_container = $('#select2-billing_state-container')
  observeRendering(billing_container, (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName == 'title') {
          h_console('Billing selected')
          $('#ship-to-different-address-checkbox').trigger('click')
        }
      }
    }
  })

  let shipping_container = $('#select2-shipping_state-container')
  observeRendering(shipping_container, (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName == 'title') {
          h_console('Shipping selected')
          $('#ship-to-different-address-checkbox').trigger('click').trigger('click')
        }
      }
    }
  })

  /** Keeps track of cart count because we don't use WC directly **/
  listenCookieChange((oldCookie, newCookie) => {
    var newCookieValue = newCookie.split('; ').find((row) => row.startsWith('woocommerce_items_in_cart='))
    newCookieValue = newCookieValue ? newCookieValue.split('=')[1] : 0
    if (newCookieValue < 1) {
      $('#menu-item-cart').removeClass('d-inline-flex').addClass('d-none')
    } else {
      $('#cartCount').html('(' + newCookieValue + ')')
    }
  }, 1000)
}

function handle_hack_tax(e) {
  h_alert('changed state')
  h_console(e)
}

function handle_remove_from_cart(e) {
  var thiz = $(e.currentTarget),
    product_id = thiz.attr('data-product_id'),
    product_name = thiz.parents('.product-remove').nextAll('.product-name').find('a').html()
  send_analytic('remove_from_cart', product_id, product_name)
}

function send_analytic(event_name, product_id, product_name) {
  log_google_analytics(document, function (element) {
    return GA.dispatch(event_name, product_name, product_id)
  })
}
