import { animateCSS, preloadEnargeableImages } from '@lib/globals.js'
import InfiniteScrollFactory from '@lib/InfiniteScrollFactory'
import handleTriggerCloseSideSlide from '@lib/handlers/handleTriggerCloseSideSlide'
import waitForScroll from '@lib/waitForScroll'

export default {
  h_show_side_slide: async () => {
    const gridWrapper = $('.h-grid-wrapper'),
      target = $('#grid-scroll,.grid-scroll'),
      sideSlide = document.getElementById('sideSlide')

    gridWrapper.data('scroll', window.scrollY)

    $('body').add(gridWrapper).addClass('show-slide')

    await animateCSS(sideSlide, 'fadeIn', 'faster')
    let infinite = new InfiniteScrollFactory()
    infinite.iceScroll()

    window.scrollTo(0, target.offset().top - 100)
    preloadEnargeableImages()

    window.addEventListener('keydown', handleTriggerCloseSideSlide)
    window.addEventListener('click', handleTriggerCloseSideSlide)
  },

  h_scroll_after_pagination: () => {
    const target = $('#nav-scroll')

    if (!target.length) {
      return
    }

    waitForScroll(window, target.offset().top)
  },

  h_clear_dirty: () => {
    $('.dirty').removeClass('dirty filtered-input').addClass('clean')
    $('.undo:not(.invisible)').addClass('invisible')
    $('.h-triggers-operation[data-callback="h_clear_dirty"]').prop('disabled', $('.dirty').length < 1)
  },

  h_clear_inquiry_form: () => {
    $('#contact-form').get(0).reset()
  },

  wishlistCallback: () => {
    // get wishlist cookie values
    var cookieValue = document.cookie.split('; ').find((row) => row.startsWith('_h_wishlist=')),
      works = cookieValue ? cookieValue.split('=')[1] : false,
      array_of_works = [],
      count = 0,
      updateHeader = (new_count) => {
        if (new_count < 1) {
          $('body.wishlist #nav-scroll').html('No Works Saved').css({ minHeight: '60vh' })
          $('.close-side-slide').trigger('click')
        }
      }

    if (works) {
      array_of_works = decodeURIComponent(works).split('|')
      count = array_of_works.length
    }
    $('#wishlistCount').html(count)
    $('#menu-item-wishlist')
      .toggleClass('d-inline-flex', count > 0)
      .toggleClass('d-none', count < 1)

    // Only applies to the wishlist page
    $('body.wishlist .h-thumb').each((idx, thumb) => {
      var isSaved = $(thumb).find('[id*="wishListButtonCaption-"] > a').hasClass('wishlist-saved')

      // Remove it
      if (!isSaved) {
        animateCSS(thumb, 'fadeOut', 'faster')
          .then(() => {
            thumb.remove()
          })
          .then(() => {
            updateHeader(count)
          })
      } else {
        updateHeader(count)
      }
    })
  },
}
