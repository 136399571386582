export default function(triggering_evt, original_event, original_target) {
  var btn = $(original_target),
    change_to_cm = btn.hasClass('inches'),
    metric = 'in'

  $('.fraction').each(function(idx, fraction) {
    var cm = $(fraction).attr('data-cm'),
      inch = $(fraction).attr('data-inches'),
      inch_fraction = $(fraction).attr('data-inches-fraction'),
      inch_fraction = inch_fraction ? JSON.parse(inch_fraction) : false
    $(fraction).html((idx, oldHtml) => {
      if (change_to_cm) return cm
      if (inch_fraction) {
        return `<span class="fraction-base">${inch_fraction['whole']}</span><span class="hyphen">&hyphen;</span><sup>${inch_fraction['numerator']}</sup><span class="frasl">&frasl;</span><sub>${inch_fraction['denominator']}</sub>`
      } else {
        return `<span class="fraction" data-inches="${inch}" data-cm="${cm}">${inch}</span>`
      }
    })
  })
  if (change_to_cm) {
    $('.fraction-metric').html('CM.')
    $(btn)
      .attr('aria-label', 'Convert to inches')
      .attr('title', 'Convert to inches')
      .removeClass('inches')
      .html('<i class="fa-solid fa-exchange-alt icon"></i> IN.')
    metric = 'cm'
  } else {
    $('.fraction-metric').html('IN.')
    $(btn)
      .attr('aria-label', 'Convert to centimeters')
      .attr('title', 'Convert to centimeters')
      .addClass('inches')
      .html('<i class="fa-solid fa-exchange-alt icon"></i> CM.')
    metric = 'in'
  }
  document.cookie = '_h_metric=' + metric + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
}
