import { log_google_analytics, scrubObject } from '@lib/globals'
import GA from '@lib/ga'

export default function(triggering_evt, original_event, original_target) {
  var was_clicked = $(original_target),
    ga_event = was_clicked.attr('data-ga-event') || 'click',
    value = was_clicked.attr('data-ga-label') || was_clicked.attr('aria-label') || was_clicked.html(),
    event_category = was_clicked.attr('data-event-category') || 'Navigation',
    event_action = was_clicked.attr('data-event-action') || 'click',
    event_meta = was_clicked.attr('data-meta'),
    post_id = was_clicked.attr('data-post-id'),
    post_type = was_clicked.attr('data-post-type')
  log_google_analytics(was_clicked, function(element) {
    return GA.dispatch(ga_event, event_category, event_action, value, post_id, event_meta, post_type)
  })
}
