export default function(original_target) {
  var target_posts = $('#targetPosts')

  // In case of a double run
  target_posts.empty()

  var btn = $(original_target),
    posts_on_page = $('[data-post-id]')
      .get()
      .map((val) => $(val).data('post-id'))

  ;[...new Set(posts_on_page)].forEach((post_id) => {
    let hidden = $(
      `<input name="${_INPUT_IDS_ON_PAGE}[]" type="hidden" data-unfiltered-key="${_INPUT_IDS_ON_PAGE}" class="filtered-input">`
    )
    hidden.val(post_id)
    target_posts.append(hidden)
  })
}
