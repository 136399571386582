import exif from 'exif-js'
import { h_alert, handle_rest_rejections, h_show_inline_alert, httpBuildQuery } from '@lib/globals.js'
import { control_submit } from '@src/routes/insert.js'

export default class UploadFiles {
  constructor(myUpload) {
    this.uploadElement = myUpload
    if (myUpload) {
      this.initDZ()
    }
  }

  isQueued() {
    return this.myDropzone.getQueuedFiles().length > 0 || this.myDropzone.files.length > 0
  }

  appendInsertedId(post_id) {
    let url = new URL(this.myDropzone.options.url),
      search_params = url.searchParams
    search_params.append(_INPUT_ID, post_id)
    this.myDropzone.options.url = url.href
  }

  uploadFiles(feedback_element) {
    this.feedback_element = feedback_element
    this.myDropzone.processQueue()
  }

  initDZ() {
    var origUploadElement = this.uploadElement,
      thiz = this,
      request_data = {}

    request_data[_INPUT_OPERATION_CLASS] = 'H\\Operations\\OperationUpload'
    if (_NONCE) {
      request_data[_INPUT_WP_NONCE] = _NONCE
      request_data[_INPUT_LOGGED_IN] = true
    }

    // Add the ID if this is an update
    var parentForm = $(origUploadElement).parents('form'),
      postId = parentForm.find('input[name="id"]').val()

    if (postId) {
      request_data[_INPUT_ID] = postId
    }

    let url = _RESTURL + 'op-upload?' + httpBuildQuery(request_data)

    this.myDropzone = new Dropzone(this.uploadElement, {
      dictDefaultMessage:
        '<div>Drop image here or click to select file</div><small>At least 1800px on the shorter dimension</small><i style="font-size: 30px" class="fa-solid fa-upload d-block icon mx-auto mt-4"></i>',
      dictRemoveFile: '<i class="fa-solid fa-times text-danger cursor-pointer"></i>',
      dictInvalidFileType: 'You cannot upload this type of file. Valid types are: jpeg or PDF',
      url: url,
      params: request_data,
      paramName: 'h-image-file', // The name that will be used to transfer
      uploadMultiple: true,
      parallelUploads: 100,
      maxThumbnailFilesize: 50,
      maxFiles: 1,
      autoProcessQueue: false,
      addRemoveLinks: true,
      maxFilesize: 10, // MB,
      acceptedFiles: 'image/jpeg', // Make sure only images are
      // accepted
      accept: function(file, done) {
        file.acceptDimensions = done
        file.rejectDimensions = function() {
          done('Image must be at least 1800px on the shorter dimension. Remove the file from the queue to try again.')
        }
      },
      init: function() {
        this.on('thumbnail', function(file, dataUrl) {
          if (!file) return
          if (!file.rejectDimensions || !file.acceptDimensions) return
          // Register for the thumbnail callback.
          // When the thumbnail is created the image dimensions are set.
          // Do the dimension checks you want to do
          var min_dim = file.width <= file.height ? file.width : file.height
          if (min_dim < 1800) {
            file.rejectDimensions()
          } else {
            file.acceptDimensions()
          }
        })
          .on('maxfilesexceeded', function() {
            h_alert(
              'You cannot upload more than one file at a time. If uploading a PDF for download, do it separately.'
            )
            return false
          })
          .on('removedfile', function(file) {
            parentForm.find('.edit-featured > div:nth-child(2)').removeClass('d-none')
            $(origUploadElement)
              .parents('.form-group')
              .find('.alert')
              .addClass('d-none')

            if (!thiz.myDropzone.getAcceptedFiles().length) {
              parentForm
                .find('[id^="DZhidden"]')
                .removeClass('dirty filtered-input')
                .addClass('clean')
            }
            control_submit(parentForm)
          })
          .on('addedfile', function(file) {
            parentForm.find('.edit-featured > div:nth-child(2)').addClass('d-none')
            $(origUploadElement)
              .parents('.form-group')
              .find('.alert')
              .addClass('d-none')
            parentForm
              .find('[id^="DZhidden"]')
              .addClass('dirty filtered-input')
              .removeClass('clean')
            control_submit(parentForm)
          })
          .on('error', function(file, errorMsg) {
            $(origUploadElement)
              .parents('.form-group')
              .find('.alert')
              .html(errorMsg)
              .removeClass('d-none')
            parentForm
              .find('.dz-error-mark')
              .prepend('<i class="fa-solid fa-exclamation-circle icon upload-error text-danger"></i>')
              .find('svg')
              .remove()

            handle_rest_rejections(errorMsg, {
              inline: true,
              inline_alert_position: 'html',
              status_class: 'alert-danger',
              animation: 'shakeX',
              inline_alert_parent: thiz.feedback_element,
            })
          })
          .on('successmultiple', function(files, response) {
            parentForm
              .find('.dz-success-mark')
              .prepend('<i class="fa-solid fa-check-circle icon upload-error text-success"></i>')
              .find('svg')
              .remove()
            h_show_inline_alert({
              response: response.data,
              enclosing_element: $(thiz.feedback_element),
              position: 'append',
              status_class: 'alert-success',
              animation: 'fadeInUp',
            })
            $('#uploadingFile')
              .parents('.h-inline-alert')
              .remove()
            thiz.myDropzone.removeAllFiles(true)
            let newImageSrcData = response.data[_METADATA],
              imageParent = parentForm.find('.edit-featured > div:nth-child(2)'),
              bust = new Date().getTime()
            imageParent.html(
              `<img src="${newImageSrcData[0]}?${bust}" width="${newImageSrcData[1]}" height="${newImageSrcData[2]}">`
            )
          })
      }, // init
    }) // new Dropzone
    this.myDropzone.autoDiscover = false
  }
}
