import { addAccents, log_google_analytics, animateCSS, searchProgress } from '@lib/globals'
import GA from '@lib/ga'
import _debounce from '@node/lodash.debounce'

const animation_entrances = [
  'fadeIn',
  'fadeInBottomLeft',
  'fadeInBottomRight',
  'fadeInDown',
  'fadeInLeft',
  'fadeInRight',
  'fadeInTopLeft',
  'fadeInTopRight',
  'fadeInUp',
  'slideInDown',
  'slideInLeft',
  'slideInRight',
  'slideInUp',
]

export default function () {
  h_console('artists')

  load_page()

  $('body').toggleClass('search-progress', false)

  const debounced_search_artist = _debounce(h_handle_search_artist, 500, { leading: false, trailing: true })

  $(document)
    .on('keyup', '#searchArtist', debounced_search_artist)
    .on('click', '.h-reset-artists', { reset: true }, load_page)
}

async function load_page(e) {
  let { reset, searchingFor } = (e && e.data) || {}

  if (reset) {
    $('#searchArtist').val('')
  }

  const frag = new DocumentFragment(),
    re = searchingFor ? new RegExp(addAccents(searchingFor), 'i') : new RegExp(/^.*$/),
    artist_objects = searchingFor
      ? local_obj.artists.filter((a) => a.name.indexOf(searchingFor) > -1 || a.name.match(re) !== null)
      : local_obj.artists

  $('.no-results').toggleClass('d-none', artist_objects.length > 0)

  for (const artist of artist_objects) {
    const full_name = esc_html(artist.name),
      work_count_annot =
        artist.count > 1 ? `<sup class="pl-2 text-muted">(${esc_html(artist.count)})</sup>` : '',
      link = _HOMEURL + 'artist/' + esc_html(artist.slug),
      grid_item = `<span class="h6 py-3 px-4"><span>${full_name}</span>${work_count_annot}</span>`,
      block = $('<a>')
        .attr('href', link)
        .attr('aria-label', full_name)
        .attr('data-post-id', esc_html(artist.id))
        .attr('data-search', full_name)
        .attr('data-module', 'handleAnalyticsClick')
        .addClass('triggers-lazy-module nav-link-raw')
        .html(grid_item)
    frag.appendChild($(block).get(0))
  }

  $('#artistsGrid').html(frag.cloneNode(true))

  await Promise.all(
    $('#artistsGrid > *')
      .get()
      .map(async (el) => {
        let args = [el],
          animation = get_random_animation()
        args.push(...animation)
        let val = await animateCSS(...args)
        return val
      })
  )

  return frag
}

function get_random_animation() {
  var random = Math.floor(Math.random() * animation_entrances.length)
  return ['faster', animation_entrances[random]]
}

function h_handle_search_artist(e) {
  var event_target = $(e.currentTarget),
    input = event_target.is('input') ? event_target : $('#searchArtist'),
    searchingFor = input.val().toLowerCase().trim()

  e.data = { searchingFor: searchingFor, reset: !searchingFor }
  load_page(e)
    .then((frag) => {
      if (frag.children.length < 1) {
        animateCSS($('.no-results').removeClass('d-none'), 'faster', 'slideInUp')
      } else {
        if (frag.children.length == 1) {
          $('#artistsGrid > *:not(.no-results)').addClass('text-center')
        }
      }
      return frag
    })
    .then((frag) => {
      if (searchingFor) {
        log_google_analytics(event_target, function (element) {
          return GA.dispatch('search_artist', searchingFor)
        })
      }
    })
}
