import {
  log_google_analytics,
  h_show_inline_alert,
  h_render_component,
  h_push_state,
  scrubObject,
  h_error,
  h_clear_dirty,
  httpBuildQuery,
  rest_entry_point,
  handle_rest_rejections,
  loadLocalizedData,
} from '@lib/globals'

import { render_work_category_management_grid } from '@src/routes/insert'

import loadModule from '@lib/loadModule'

import GA from '@lib/ga'

import { pushNewScroll } from '@lib/InfiniteScrollFactory'

export default async function(original_target) {
  original_target = $(original_target)
  const request_data = collectOperationRequestData(original_target),
    target_save_html = original_target.html(),
    operation = original_target
      .attr('data-operation-class')
      .split('\\')
      .pop(),
    route = local_obj.route_map[operation]['route'],
    method = local_obj.route_map[operation]['method'],
    icon = '<i class="fa-solid fa-circle-notch fa-spin icon"></i>'

  // avoid double submission
  original_target.addClass('disabled')

  let token = await grecaptcha_promise()

  if (null !== token) {
    request_data[_INPUT_GRECAPTCHA] = token
  }

  rest_entry_point({
    route: route,
    request_data: request_data,
    beforeSend: () => {
      original_target.not('.no-progress').html(icon)
      original_target
        .parents('form')
        .find('.h-alert')
        .empty()
    },
    always: () => original_target.html(target_save_html),
    method: method,
    cache: method == 'GET',
  })
    .then((body) => {
      let rendering_target = original_target.attr('data-target')
      return {
        body: body,
        original_target: original_target,
        request_data: request_data,
        local_data: {
          feedback_element: original_target.attr('data-feedback-target'),
          rendering_target: $(rendering_target), // converting target to jquery obj
          callback: original_target.attr('data-callback'),
          operation_class: operation,
        },
      }
    })

    // Render what needs rendering
    .then((data) => {
      let { body, original_target, request_data, local_data } = data
      if (body[_RENDER]) {
        h_render_component({
          target_container: local_data.rendering_target,
          render: body[_RENDER],
          data: data,
        })
          // Create a new infinite scroll ( putting any existing on ice )
          .then((data) => {
            pushNewScroll(data.local_data.rendering_target)
          })
      }
      // Don't wait for promise beyond this
      return data
    })

    // Analytics
    .then((data) => {
      issue_analytics(data)
      return data
    })

    // Form and Dropzone
    .then((data) => {
      issue_postform_and_dropzone_handling(data)
      return data
    })

    // Issue the callback, if any
    // Do this before setting up a new scroll
    .then((data) => {
      issue_callback(data)
      return data
    })

    // Show success
    .then((data) => {
      let { body, original_target, request_data, local_data } = data
      h_show_inline_alert({
        response: body,
        enclosing_element: $(local_data.feedback_element),
        position: original_target.attr('data-feedback-mode') || 'html',
        status_class: 'alert-success',
        animation: 'fadeInUp',
      })

      // Re-enable target
      original_target.removeClass('disabled')

      return data
    })

    .then((data) => {
      if ($('body.insert').length) {
        loadLocalizedData()
          .then((body) => {
            // re-attach all the Awesomplete's (ugh)
            // Artist
            let artist = Awesomplete.all.find((a) => $(a.input).attr('id') == 'artist-crud-work'),
              media = Awesomplete.all.find((a) => $(a.input).attr('id') == 'media-crud-work'),
              wc = Awesomplete.all.filter((a) =>
                $(a.input)
                  .attr('id')
                  .match(/work-categories-crud-work|work-categories-crud-exhibition/)
              )

            if (artist) artist._list = body.artists
            if (media) media._list = body.media
            if (wc && wc.length) {
              wc.forEach((wc_list, i) => {
                wc_list._list = body.work_categories
              })
            }
            return body
          })
          .then((body) => {
            render_work_category_management_grid()
          })
      }
      return data
    })

    // Update the push state
    .then((data) => {
      if (data.body[_PUSH_STATE_URL]) {
        h_push_state(data.body[_PUSH_STATE_URL], data)
      }
    })

    .catch((error) => {
      // Re-enable target
      original_target.removeClass('disabled')

      handle_rest_rejections(error, {
        inline_alert_parent: $(original_target.attr('data-feedback-target')),
        inline: true,
        inline_alert_position: 'html',
        status_class: 'alert-danger',
        animation: 'shakeX',
      })
    })
}

function collectOperationRequestData(original_target) {
  var request_data = {},
    original_target = $(original_target),
    container = original_target.attr('data-container') || original_target.parents('form'),
    // component targeted to show rendered results, i.e. "#updateGrid"
    unfiltered_input = $(container)
      .find('.filtered-input:not(.disabled):not(.clean)')
      .get()
      .filter((el) => {
        let name = $(el).attr('name')
        if (name) {
          return name.indexOf('[]') < 0
        }
        return true
      }),
    unfiltered_input_array = $(container)
      .find('.filtered-input:not(.disabled):not(.clean)')
      .get()
      .filter((el) => {
        let name = $(el).attr('name')
        if (name) {
          return name.indexOf('[]') > -1
        }
        return false
      }),
    meta = original_target.attr('data-meta') ? JSON.parse(original_target.attr('data-meta')) : null

  if (meta) request_data[_INPUT_DATA_META] = meta

  var array_of_input_arrays = []
  unfiltered_input_array.forEach((input, idx) => {
    var name = $(input).attr('data-unfiltered-key')
    if (!array_of_input_arrays.includes(name)) {
      var sibling_elements = $(container).find('[data-unfiltered-key="' + name + '"]'),
        input_array = sibling_elements.map((idx, el) => {
          return el.value
        })
      request_data[name] = input_array.get()
      array_of_input_arrays.push(name)
    }
  })

  $(unfiltered_input).each(function(idx, input) {
    input = $(input)
    var name = input.attr('data-unfiltered-key'),
      checkbox_values = {}
    if (
      input.is(
        'input[type="text"],input[type="range"],input[type="date"],input[type="number"],input[type="tel"],input[type="email"],input[type="url"],input[type="hidden"],textarea:not(.tinymce-enabled)'
      )
    ) {
      request_data[name] = input.val() || ''
    } else if (input.is('textarea.tinymce-enabled')) {
      var id = input.attr('id'),
        editor = tinyMCE.get(id)
      request_data[name] = editor.getContent()
    } else if (input.is('input[type="radio"]')) {
      if (input.is(':checked')) {
        request_data[name] = input.val()
      }
    } else if (input.is('select')) {
      request_data[name] = input.children('option:selected').val()
    }
  })

  // Which button, save or save as?
  var is_saving = original_target.is('[name^="save"]'),
    save_or_save_as = original_target.attr('data-unfiltered-key')
  if (is_saving && save_or_save_as) {
    request_data[save_or_save_as] = true
  }

  // Handle checkboxes separately
  var all_checkboxes = $(container).find('input[type="checkbox"]'),
    checkboxMap = {} // name => { values, ... }

  all_checkboxes.each(function(idx, cb) {
    var cb_name = $(cb).attr('data-unfiltered-key'),
      cb_value = $(cb).val(),
      cb_checked = $(cb).is(':checked')
    if (!cb_checked) return
    if (checkboxMap[cb_name]) {
      checkboxMap[cb_name].push(cb_value)
    } else {
      // If it's a single checkbox boolean, don't pass as an array
      checkboxMap[cb_name] = cb_value === 'true' ? true : cb_value === 'false' ? false : [cb_value]
    }
  })
  $.each(checkboxMap, function(i, e) {
    request_data[i] = e
  })

  return request_data
}

function issue_callback(data) {
  let { body, original_target, request_data, local_data } = data
  if (!local_data.callback) {
    return
  }
  // import and call the callback
  loadModule('lib', 'handlers/componentCallbacks.js')
    .then((module) => {
      const callback = local_data.callback
      const moduleObject = module.default
      const lazy = moduleObject[callback]
      lazy(data)
    })
    .catch((error) => {
      h_error('Error in: componentCallbacks.js | handler: ' + local_data.callback, error)
      h_error(error)
      throw new Error(error)
    })
}

function issue_analytics(data) {
  let { body, original_target, request_data, local_data } = data

  log_google_analytics(original_target, function(element) {
    // Take out the junk
    const gtag_meta = {}
    $.each(request_data, (idx, val) => {
      if (
        idx.indexOf(_INPUT_CACHE_KEY) !== -1 ||
        (idx.indexOf(_INPUT_DATA_META) !== -1 && Object.keys(val).length < 1) ||
        !val ||
        val.length == 0
      ) {
        return true
      }
      gtag_meta[idx] = val
    })
    return GA.dispatch(
      original_target.attr('data-ga-event') || local_data.operation_class,
      'Operation', // category
      'submit', // action
      original_target.attr('data-ga-label') || original_target.attr('aria-label'), // label
      original_target.attr('data-post-id') || null, // post_id
      scrubObject(gtag_meta), // meta
      original_target.attr('data-post-type') || null // post_type
    )
  })
}

function issue_postform_and_dropzone_handling(data) {
  let { body, original_target, request_data, local_data } = data
  var parentForm = original_target.parents('form').attr('id')
  if (
    local_data.operation_class == 'OperationSave' &&
    window.dropzoneInstance &&
    dropzoneInstance[parentForm] &&
    dropzoneInstance[parentForm].isQueued()
  ) {
    const uploadFilesObj = dropzoneInstance[parentForm]

    h_show_inline_alert({
      response: {
        msg: '<span id="uploadingFile">Uploading file <i class="fa-solid fa-spin fa-circle-notch ml-1"></i></span>',
      },
      enclosing_element: $(local_data.feedback_element),
      position: 'append',
      status_class: 'alert-info',
      animation: 'fadeInRight',
    })

    if (body[_INPUT_ID]) {
      uploadFilesObj.appendInsertedId(body[_INPUT_ID])
    }
    uploadFilesObj.uploadFiles(local_data.feedback_element)
  }
  if (body[_RESET_FORM] && $(original_target).parents('form').length > 0) {
    $(original_target)
      .parents('form')[0]
      .reset()
  }
}

async function grecaptcha_promise() {
  if (typeof grecaptcha === 'undefined') {
    return Promise.resolve(null)
  }

  let gcPromise = new Promise((resolve, reject) => {
    grecaptcha.ready(function() {
      // do request for recaptcha token
      // response is promise with passed token
      grecaptcha
        .execute(_GRECAPTCHA_TOKEN, {
          action: 'new_inquiry',
        })
        .then(function(token) {
          resolve(token)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }) // intentionally not catching here so you can do it locally

  let token = await gcPromise

  return token
}
