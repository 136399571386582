import { restoreOriginalState, animateCSS } from '@lib/globals.js'
import InfiniteScrollFactory from '@lib/InfiniteScrollFactory'
import handleTriggerCloseSideSlide from '@lib/handlers/handleTriggerCloseSideSlide'

const init = async (triggering_evt, original_event, original_target) => {
  var wrapper = $('.h-grid-wrapper'),
    scroll = wrapper.data('scroll')

  let infinite = new InfiniteScrollFactory(),
    sideSlide = document.getElementById('sideSlide')
  infinite.activateScroll()
  await animateCSS(sideSlide, 'fadeOut', 'faster')
  wrapper.add('body').removeClass('show-slide')
  window.scrollTo(0, scroll)
  restoreOriginalState('quickview')
  window.removeEventListener('keydown', handleTriggerCloseSideSlide)
  window.removeEventListener('click', handleTriggerCloseSideSlide)
}

const finalize = () => {}

export default {
  init,
}
