require('jquery.easing')

import { font_spy, is_object, preloadEnargeableImages, animateCSS } from '@lib/globals'

import loadModule from '@lib/loadModule'
import InfiniteScrollFactory from '@lib/InfiniteScrollFactory'
import handleTriggerCloseSideSlide from '@lib/handlers/handleTriggerCloseSideSlide'
import waitForScroll from '@lib/waitForScroll'

export async function h_init() {
  h_console('common')

  if ($('html').hasClass('fonts-loaded') || $('html').hasClass('fontawesome-i2svg-complete')) {
    $('body').addClass('prepainting-fade')
  }

  // Explode all args to window
  ;(function () {
    const explodeArgs = (node) => {
      var entries = node
      if (is_object(entries)) {
        entries = Object.entries(node)
      }
      try {
        entries.forEach(([key, value]) => {
          if (is_object(value) && !Array.isArray(value)) {
            explodeArgs(value)
          } else {
            window['_' + key.toUpperCase()] = value
          }
        })
      } catch (e) {
        // large embedded data is not explodable
      }
    }
    explodeArgs(local_obj.constants)
  })()

  control_scrolling() // true init

  // Set the default metric
  if (!document.cookie.split(';').some((item) => item.trim().startsWith('_h_metric='))) {
    // Doesn't exist, create it default inches
    document.cookie = '_h_metric=in; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
  }

  h_finalize()

  try {
    await font_spy()
    const prepainter = document.getElementById('prepainter'),
      remove_prepainter_classes = (e) => {
        $('body').removeClass('prepainting prepainting-fade')
      }
    prepainter.addEventListener('transitionend', remove_prepainter_classes, { once: true })
    $('body').addClass('prepainting-fade')
  } catch (e) {
    throw e
  }

  if (window.location.search.match(/slide=/)) {
    var target = $('#grid-scroll')
    waitForScroll(window, target.offset().top - 100)
    window.addEventListener('keydown', handleTriggerCloseSideSlide)
    window.addEventListener('click', handleTriggerCloseSideSlide)
  }

  // Preload images for enlarge
  preloadEnargeableImages()

  // Mobile shit
  watchForHover()

  // Initialize InfiniteScroll
  let infScroll = new InfiniteScrollFactory()

  // Pre-cache enlarge lazy modules
  loadModule('lib', 'handlers/enlargeImage')

  // Init autocomplete
  loadModule('lib', 'handlers/initSearchBarToggles').then((module) => {
    const def = module.default
    def()
  })
  ;(function () {
    if ($('#h-delegate').length < 1) return
    $('#h-delegate')
      .tooltip({
        container: 'body',
        placement: 'top',
        selector: '[data-toggle="tooltip"]',
      })
      .on('shown.bs.tooltip', function () {
        const tooltipInner = $('.tooltip .tooltip-inner')
        tooltipInner.css({
          border: '1px solid #2e6da4',
        })
        animateCSS(tooltipInner, 'pulse', 'faster')
      })
  })()
}

function h_finalize() {
  // Aria cleanup
  $('input[name="_mc4wp_honeypot"]').parents('label').attr('for', 'mc4wp-form-1')
  $('#mc4wp-form-1').attr('aria-label', 'Mailchimp')
  $('.checkout .select2-selection__rendered').addClass('form-control')

  if (!__PRODUCTION_BUILD__) {
    import(/* webpackChunkName: "vendors-a11yChecker" */ '@node/a11y-checker/src/a11y.js').then((module) => {
      var a11y = module.default
      a11y()
    })

    $('body').prepend($('.h-error'))
  }
}

function control_scrolling() {
  $('a.h-scroll').click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault()
        waitForScroll(window, target.offset().top)
          .then((done) => {
            // Must change focus!
            var target = $(target)
            target.focus()
            if (target.is(':focus')) {
              // Checking if the target was focused
              return false
            } else {
              target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
              target.focus() // Set focus again
            }
          })
          .catch((error) => {
            throw new Error(error)
          })
      }
    }
  })
}

function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  // that are fired after touchstart events. Since they're
  // indistinguishable from real events, we use the fact that they're
  // fired a few milliseconds after touchstart to filter them.
  let lastTouchTime = 0

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return
    document.body.classList.add('hasHover')
  }

  function disableHover() {
    document.body.classList.remove('hasHover')
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date()
  }

  document.addEventListener('touchstart', updateLastTouchTime, true)
  document.addEventListener('touchstart', disableHover, true)
  document.addEventListener('mousemove', enableHover, true)

  enableHover()
}
