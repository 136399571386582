let moduleCache = {}

/**
 *   Retrieves via lazy load or from cache
 *   @param  {string} module_name must be sent absolute path
 *   @return {Object} The module promise
 */
export default function(path_alias, module_name) {
  if (module_name) module_name = module_name.replace(/\.js$/, '')
  let key = module_name || path_alias
  return new Promise((resolve, reject) => {
    if (!moduleCache[key]) {
      let imported
      switch (path_alias) {
        case 'lib':
          imported = import(/* webpackChunkName: "[request]" */ `@lib/${module_name}`)
          break
        case 'tab':
          imported = import(/* webpackChunkName: "tab" */ '@node/bootstrap/js/dist/tab')
          break
        case 'alert':
          imported = import(/* webpackChunkName: "tab" */ '@node/bootstrap/js/dist/alert')
          break
        case 'modal':
          imported = import(/* webpackChunkName: "modal" */ '@node/bootstrap/js/dist/modal')
          break
        default:
      }
      imported
        .then((module) => {
          moduleCache[key] = module
          h_console(key, 'lazy loaded and added to cache')
          resolve(module)
        })
        .catch((error) => {
          if (error.name === 'ChunkLoadError') {
            hardReload()
          }
          h_error(error)
        })
      return
    }
    h_console(key, 'loaded from cache')
    resolve(moduleCache[key])
  })
}
