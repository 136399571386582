import { log_google_analytics } from '@lib/globals'
import GA from '@lib/ga'
import loadModule from '@lib/loadModule'

export default function(triggering_evt, original_event, original_target) {
  var modal = $('#h-enlarge'),
    img = original_target.is('img') ? original_target : original_target.parents('[id*=workDetail]').find('img'),
    image_src = img.attr('data-full-image'),
    alt = img.attr('alt'),
    post_id = img.attr('data-post-id'),
    mediaQuery = window.matchMedia('(min-width: 576px)')

  // Enlarge is awkward for mobile - and unnecessary
  if (!mediaQuery.matches) {
    return false
  }

  log_google_analytics(original_target, function(element) {
    return GA.dispatch('enlarge', alt, post_id)
  })

  loadModule('modal').then((module) => {
    modal
      .find('img')
      .attr('src', image_src)
      .attr('alt', alt)
    modal.modal('show')
  })
}
