import {
  log_google_analytics,
  h_render_component,
  h_push_state,
  scrubObject,
  collectComponentStateRequestData,
  h_error,
  rest_entry_point,
  handle_rest_rejections,
} from '@lib/globals'

import loadModule from '@lib/loadModule'

import GA from '@lib/ga'

import { pushNewScroll } from '@lib/InfiniteScrollFactory'

/**
 *
 * This function fetches and renders all triggered events
 * It should never change because the data is all passed in from each triggering elements's attributes
 *
 * @param event
 *            Event with (data-)access and (data-)component references passed
 * @param original_target
 *            What triggered the state change
 * @param component_wrapper
 *            The enclosing component element in the DOM
 * @param args
 *            Object containing query args to send
 * @returns
 */
export default function(original_target, component_wrapper, callback) {
  const request_data = collectComponentStateRequestData(original_target, component_wrapper)

  const beforeSend = (xhr) => {
      original_target
        .parents('.h-thumb')
        .find('.h-thumb-image')
        .addClass('progress')
    },
    always = (response) => {
      original_target
        .parents('.h-thumb')
        .find('.h-thumb-image')
        .removeClass('progress')
    }

  rest_entry_point({
    request_data: request_data,
    route: 'ui-component',
    beforeSend: beforeSend,
    always: always,
  })
    .then((body) => {
      return {
        body: body,
        original_target: original_target,
        component_wrapper: component_wrapper,
        grid_wrapper: false, // also the gridwrapper in case of infinite scroll
        callback: callback,
        append: false,
        request_data: request_data,
      }
    })
    .then((data) => componentStateDone(data))
    .catch((responseJSON) => {
      handle_rest_rejections(responseJSON, {
        inline: false,
        // inline_alert_parent: $(component_wrapper),
        // inline_alert_position: 'after'
      })
    })
}

// This is actually used by both handleComponentState AND handleOperationSubmitted (when in infinite context)
export function componentStateDone(data) {
  // Infinite on the body means don't reacreate the wrapper, just insert the grid
  const isInfinite = $('body').hasClass('infinite')
  var target_container = isInfinite ? data.grid_wrapper : data.component_wrapper
  h_render_component({
    target_container: target_container,
    render: data.body[_RENDER],
    append: data.append,
    data: data,
  })
    .then((data) => {
      if (!isInfinite && data.callback) {
        loadModule('lib', 'handlers/componentCallbacks')
          .then((module) => {
            const callback = data.callback
            const moduleObject = module.default
            const lazy = moduleObject[callback]
            lazy(data)
          })
          .catch((error) => {
            h_error('Error in: componentCallbacks.js | handler: ' + data.callback, error)
            h_error(error)
            throw new Error(error)
          })
      }
      return data
    })

    // Create a new infinite scroll ( putting any existing on ice )
    .then((data) => {
      if (!isInfinite && data.original_target.hasClass('reset-scroll')) {
        pushNewScroll(data.component_wrapper)
      }
      return data
    })

    .then((data) => {
      var ot = data.original_target
      log_google_analytics(ot, function(element) {
        var component_class = $(data.component_wrapper).attr('data-access-class'),
          class_arr = component_class.split('\\'),
          the_class = class_arr.pop(),
          the_page = ot.attr('href') || ot.siblings('a').attr('href'),
          data_args = ot.attr('data-args') ? scrubObject(JSON.parse(ot.attr('data-args'))) : {},
          data_meta = ot.attr('data-meta') ? scrubObject(JSON.parse(ot.attr('data-meta'))) : {},
          stripped_args = { ...data_args, ...data_meta }

        return GA.dispatch(
          ot.attr('data-ga-event') || 'click', // event name
          the_class, // category
          'click', // action
          ot.attr('data-ga-label') || ot.attr('aria-label') || JSON.stringify(ot.attr('data-args')), // label
          ot.attr('data-post-id') || null, // post_id
          stripped_args || null, // meta
          ot.attr('data-post-type') || null // post_type
        )
      })
      return data
    })

    .then((data) => {
      const body = data.body
      if (body[_PUSH_STATE_URL]) {
        h_push_state(body[_PUSH_STATE_URL], data)
      }
      return data
    })

    .then((data) => {
      const body = data.body
      // Set the paged localized global to signal to infinite scroll ok or not
      _PAGINATION = body[_PAGINATION]
      return data
    })

    .catch((error) => {
      h_error(error)
    })
}
