import { addAccents, log_google_analytics, rest_entry_point, h_console, h_error } from '@lib/globals'
import GA from '@lib/ga'
import _debounce from '@node/lodash.debounce'

export default class AutocompleteAddress {
  constructor(myInput) {
    if (!AutocompleteAddress.instance) {
      // Singleton instance
      AutocompleteAddress.instance = this

      const Awesomplete = require('awesomplete')

      this.myInput = $(myInput)[0]
      this.awesomplete = new Awesomplete(this.myInput, this.getOptions())
      this.addListeners()

      window.AutocompleteAddress = this
    }
    return AutocompleteAddress.instance
  }
  addListeners() {
    const handle_keyup = (e) => {
        !!$(this.myInput)
          .val()
          .trim().length && this.get_data()
        $('#inquire-city,#inquire-state,#inquire-zip')
          .get()
          .map((i) => $(i).val(''))
      },
      debouncedKeystroke = _debounce(handle_keyup, 500, { leading: false, trailing: true })

    this.myInput.addEventListener('keyup', debouncedKeystroke)

    /** Triggers after selection, ready to submit url **/
    this.myInput.addEventListener('awesomplete-selectcomplete', (e) => {
      let prediction = e.text.value

      log_google_analytics(e, function(event) {
        return GA.dispatch('address_lookup', prediction.label)
      })

      // transfer the found address to the fields
      $('#inquire-address').val(prediction.name)
      $('#inquire-city').val(prediction.locality || prediction.administrative_area)
      $('#inquire-state').val(prediction.region_code || prediction.region)
      $('#inquire-zip').val(prediction.postal_code)

      // Clear out the list each time otherwise previous search will pop up
      this.awesomplete._list = void 0
      this.awesomplete.suggestions = []
    })

    this.myInput.addEventListener('awesomplete-close', function(e) {})
  }
  getOptions() {
    return {
      minChars: 4,
      maxItems: 10,
      item: function(item, input, item_id) {
        var html =
            input.trim() === ''
              ? item
              : item.replace(RegExp(Awesomplete.$.regExpEscape(input.trim()), 'gi'), '<mark>$&</mark>'),
          html = '<span>' + html + '</span>'

        return Awesomplete.$.create('li', {
          innerHTML: html,
          class: 'single-suggestion',
          role: 'option',
          'aria-selected': 'false',
          id: 'awesomplete_list_' + this.count + '_item_' + item_id,
        })
      },
      data: function(item, input) {
        return {
          label: item.label,
          value: item,
        }
      },
      replace: function(text) {
        this.input.value = decodeHtml(text)
      },
      sort: function(a, b) {
        var typeA = a.value.confidence
        var typeB = b.value.confidence
        return typeB - typeA
      },
      filter: function(text, input) {
        // var re = new RegExp(addAccents(input), 'i');
        // if (text.label.indexOf(input.toLowerCase()) > -1) return true;
        // if (text.label.match(re) !== null) return true;
        return true
      },
    }
  } // end getOptions()

  async get_data() {
    var the_input = $(this.myInput),
      search = the_input.val().trim(),
      current_count = search.length,
      min_chars = 5,
      awesomplete_obj = this.awesomplete

    if (current_count >= min_chars) {
      try {
        const body = await rest_entry_point({
          route: 'ui-search-autocomplete-address',
          request_data: { search_term: search },
        })

        h_console(body)
        awesomplete_obj.list = body.data
        awesomplete_obj.evaluate()
      } catch (error) {
        h_error(error)
      }
    }
  }
}

function decodeHtml(html) {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
