const GA = {
  dispatch: (task, ...args) => {
    var taskobj = GA[task] || GA['generic']
    if (taskobj instanceof Function) {
      taskobj = taskobj(args)
    }
    let meta = taskobj['event_meta'] || {}
    if (typeof meta != 'object') {
      meta = JSON.parse(meta)
    }
    if (Object.keys(meta).length > 0) {
      delete taskobj['event_meta']
      $.each(meta, (idx, itm) => {
        if (typeof itm === 'object') {
          meta[idx] = JSON.stringify(itm)
        }
      })
    }
    let event = { event_name: task } // keep the task name if generic
    return { ...taskobj, ...event, ...meta }
  },
  remove_from_cart: (args) => {
    return {
      event_category: 'Cart',
      event_action: 'click',
      event_label: args[0],
      event_post_id: args[1],
      event_post_type: 'product',
    }
  },
  search: (args) => {
    return {
      target_page: args[0],
      event_category: args[1],
      event_action: 'search',
      event_label: args[2],
      event_post_id: args[3],
      event_post_type: args[4] || null,
    }
  },
  search_artist: (args) => {
    return {
      event_category: 'Artist',
      event_action: 'search',
      event_label: args[0],
      event_post_type: 'artist',
    }
  },
  enlarge: (args) => {
    return {
      event_category: 'Navigation',
      event_action: 'enlarge',
      event_label: args[0],
      event_post_id: args[1] || null,
      event_post_type: 'product',
    }
  },
  address_lookup: (args) => {
    return {
      event_category: 'Contact',
      event_action: 'search',
      event_label: args[0],
    }
  },
  humanScale: (args) => {
    return {
      event_category: 'Navigation',
      event_action: 'HumanScale',
      event_label: args[0],
      event_post_id: args[1] || null,
      event_post_type: 'product',
    }
  },
  generic: (args) => {
    return {
      event_category: args[0],
      event_action: args[1],
      event_label: args[2],
      event_post_id: args[3] || null,
      event_meta: args[4] || null,
      event_post_type: args[5] || null,
    }
  },
}

export default GA
