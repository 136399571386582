import { addAccents, log_google_analytics } from '@lib/globals'
import GA from '@lib/ga'
import _truncate from '@node/lodash.truncate'

export class AutocompleteImpl {
  constructor(myInput) {
    this.inputElement = myInput
    this.addListeners()
  }

  getOptions() {
    return {
      minChars: 3,
      maxItems: 25,
      item: function(text, input, item_id) {
        var html =
            input.trim() === ''
              ? text
              : text.replace(RegExp(Awesomplete.$.regExpEscape(input.trim()), 'gi'), '<mark>$&</mark>'),
          by_line = text.value.artist ? '<span class="byline"> by ' + text.value.artist + '</span>' : ''
        html += by_line || ''
        html = '<span>' + html + '</span><span>' + text.value.type + '</span>'
        return Awesomplete.$.create('li', {
          innerHTML: html,
          class: 'single-suggestion',
          role: 'option',
          'aria-selected': 'false',
          id: 'awesomplete_list_' + this.count + '_item_' + item_id,
        })
      },
      data: function(item, input) {
        return {
          label: _truncate(item.name, { length: 50, separator: ' ', omission: ' ...' }),
          value: item.meta,
        }
      },
      replace: function(text) {
        this.input.value = decodeHtml(text)
      },
      sort: function(a, b) {
        var typeA = a.value.type.toUpperCase() // ignore upper and lowercase
        var typeB = b.value.type.toUpperCase() // ignore upper and lowercase
        var labelA = a.label.toUpperCase()
        var labelB = b.label.toUpperCase()
        // first by Type
        if (typeA < typeB) {
          return -1
        }
        if (typeA > typeB) {
          return 1
        }
        // Then by Label
        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
        // names must be equal
        return 0
      },
      filter: function(text, input) {
        var re = new RegExp(addAccents(input), 'i')
        if (text.label.indexOf(input.toLowerCase()) > -1) return true
        if (text.label.match(re) !== null) return true
        return false
      },
    }
  } // end getOptions()

  addListeners() {
    /** Triggers after selection, ready to submit url **/

    this.inputElement.addEventListener('awesomplete-selectcomplete', function(e) {
      h_console(JSON.stringify(e))

      var post_type = ''
      switch (e.text.value.type) {
        case 'Work of Art':
          post_type = 'product'
          break
        case 'Artist':
          post_type = 'artist'
          break
        case 'Exhibition':
          post_type = 'exhibition'
          break
        default:
      }

      log_google_analytics(e, function(event) {
        return GA.dispatch(
          'search',
          e.text.value.link,
          e.text.value.type,
          decodeHtml(e.text.label),
          e.text.value.id,
          post_type
        )
      })

      // Navigate
      window.location.href = e.text.value.link
    })

    this.inputElement.addEventListener('awesomplete-close', function(e) {})
  }
}

function decodeHtml(html) {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
