import GA from '@lib/ga'
import loadModule from '@lib/loadModule'

export default function(triggering_evt, original_event, original_target) {
  var img = original_target.parents('[id*=workDetail]').find('img'),
    image_src = img.attr('data-full-image'),
    alt = img.attr('alt')

  let extension = image_src.split('.').pop(),
    filename = original_target.attr('data-filename').toLowerCase()

  downloadURI(image_src, filename)
}

function downloadURI(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
    })
    .catch((e) => {
      h_error(e)
    })
}
