import loadModule from '@lib/loadModule'

export function h_debug(arg) {
  if (!arg) {
    return document.cookie.split(';').filter((item) => item.trim().startsWith('_h_debug=')).length
  }
  return document.cookie.split(';').some((item) => item.includes('_h_debug=' + arg))
}

/** IMPORTANT: PLACE THIS IN wp-config **/
/** Secret_KEY: 6LdRnI8UAAAAAKRGUgqpbkdkRUmjvwHuJXyFmsPG **/

export function is_production() {
  return !window.location.origin.match(/(childsgallery\.lo|maude|10.0.1)/)
}

export function font_spy() {
  require('jquery-fontspy/jQuery-FontSpy.js')
  fontSpy('Jost,"Cormorant Garamond","Font Awesome 6 Brands", "Font Awesome 6 Free"', {
    success: function() {
      return Promise.resolve('font_spy')
    },
    failure: function() {
      return Promise.reject('font_spy did not load')
    },
  })
}

/**
 * @param element
 *            In case you want to use it in callback
 * @param f_event
 *            return the requisite analytics event object
 * @returns void
 *
 * <pre>
 * log_google_analytics($(btn), function(element) {
 *     return {
 *         page : '/party-orig/',
 *         eventCategory : &quot;filter-position&quot;,
 *         eventAction : &quot;click&quot;,
 *         eventLabel : filterby ? filterby : &quot;reset&quot;
 *     };
 * });
 * </pre>
 */
export function log_google_analytics(element, f_event) {
  var event_obj = []
  if (f_event && f_event instanceof Function) {
    event_obj = f_event(element)
  }
  if (event_obj) {
    try {
      var gtag_data_base = {
          page_path: location.pathname,
          page_title: document.title,
          page_location: location.href,
          timestamp: timestamp(),
          version: local_obj.version,
        },
        gtag_obj = { ...gtag_data_base, ...event_obj }

      // sanitize all properties to remove unwanted chars
      var sanitized_gtag = {}

      for (var [idx, item] of Object.entries(gtag_obj)) {
        idx = idx.replace(/[^\w]/g, '_').toLowerCase()
        if (item !== null) {
          sanitized_gtag[idx] = item
        }
      }

      if (h_debug('incognito')) {
        throw 'Incognito mode on, skipping all analytics'
      }
      if (typeof gtag !== 'function') {
        throw 'gtag disabled, skipping all analytics'
      }
      gtag('event', event_obj.event_name, sanitized_gtag)

      h_console('GA SENT: Analytics Object', sanitized_gtag)

      // gtag has debugging capabilities, ga() does not
      if (!is_production() || typeof ga !== 'function') {
        throw 'ga disabled, skipping UA analytic'
      }
      ga('set', 'page', location.pathname)
      ga('send', 'pageview')
      ga('send', {
        hitType: 'event',
        eventCategory: sanitized_gtag.event_category,
        eventAction: sanitized_gtag.event_action,
        eventLabel: sanitized_gtag.event_label,
      })
    } catch (err) {
      h_console(err)
      h_console('UNSENT: Analytics Object', sanitized_gtag)
    }
  }
}

export function timestamp() {
  var tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString()
  return localISOTime.replace(/z|t/gi, ' ').trim()
}

export function h_is_mobile() {
  var check = false
  ;(function(a, b) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export function addAccents(input) {
  var retval = input
  retval = retval.replace(/([ao])e/gi, '$1')
  retval = retval.replace(/e/gi, '[eèéêë]')
  retval = retval.replace(/c/gi, '[cç]')
  retval = retval.replace(/i/gi, '[iîï]')
  retval = retval.replace(/u/gi, '[uùûü]')
  retval = retval.replace(/y/gi, '[yÿ]')
  retval = retval.replace(/s/gi, '(ss|[sß])')
  retval = retval.replace(/a/gi, '([aàâä]|ae)')
  retval = retval.replace(/o/gi, '([oôö]|oe)')
  return retval
}

export function validate_input_fields(form, btn) {
  // Set up form validation.. see https://getbootstrap.com/docs/4.0/components/forms/?#validation

  if (!$(form).hasClass('needs-validation')) {
    return true
  }

  var validateFields = function(input) {
    return $(input)[0].checkValidity()
  }

  $(form)
    .find('.is-invalid')
    .removeClass('is-invalid')

  var required_fields = $(form).find('input[required="required"]'),
    needs_validation = $(form).find('.needs-validation'),
    passed_validation = true

  required_fields.add(needs_validation).each(function(idx, input) {
    if (validateFields(input) === false) {
      passed_validation = false
    }
  })

  $(form)
    .find(':invalid')
    .add($(btn))
    .toggleClass('is-invalid', !passed_validation)

  return passed_validation
}

export function h_error(msg, ...args) {
  // Only log if cookie is present or not production
  var h_debug = false
  try {
    h_debug = document.cookie.split(';').filter((item) => item.trim().startsWith('_h_debug=')).length
  } catch (e) {}

  if (!h_debug && is_production()) {
    return
  }

  h_console(msg, args)
  if (msg instanceof Error) {
    console.error(msg)
  }

  console.trace()
}

export function h_console() {
  var MyDate = new Date()
  var MyDateString
  MyDateString =
    '[' +
    ('0' + MyDate.getDate()).slice(-2) +
    '-' +
    (MyDate.toLocaleString('default', { month: 'short' }).toUpperCase() +
      '-' +
      MyDate.getFullYear() +
      ' ' +
      ('0' + MyDate.getHours()).slice(-2) +
      ':' +
      ('0' + MyDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + MyDate.getSeconds()).slice(-2)) +
    ']'

  var log = console.log

  // Only log if cookie is present or not production
  var h_debug = false
  try {
    h_debug = document.cookie.split(';').filter((item) => item.trim().startsWith('_h_debug=')).length
  } catch (e) {}

  if (!h_debug && is_production()) {
    return
  }

  // 1. Convert args to a normal array
  var args = Array.from(arguments)
  // OR you can use: Array.prototype.slice.call( arguments );

  // 2. Prepend log prefix log string
  args.unshift(MyDateString + ':')

  // 3. Pass along arguments to console.log
  log.apply(console, args)
}

export function is_object(val) {
  if (val === null) {
    return false
  }
  return typeof val === 'function' || typeof val === 'object'
}

export function h_alert(options) {
  let { msg, callback, more_buttons_obj, timer, alert_color } = options
  let args = [...arguments]
  if (typeof args[0] === 'string' || '' instanceof String) {
    msg = args[0]
  }
  more_buttons_obj = more_buttons_obj || {}

  var callback_return = false,
    close = '<button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">Dismiss</button>',
    modal = $('#h-modal-alert')

  if (alert_color) {
    modal
      .find('svg')
      .removeClass('text-danger')
      .addClass(alert_color)
  }

  /*
   * Read the button obj, pass multiple ok
   * var typical_arg = {
   *      arbitrary_button_1: { label: "Save", classes: "class1 class2 ... ", },
   *      arbitrary_button_2: { label: "Something Else", classes: "class1 class2 ... ", },
   * };
   */

  const strayButtons = modal.find('.modal-footer').find('button')
  if (strayButtons.length > 0) {
    strayButtons.remove()
  }

  $.each(more_buttons_obj, function(index, button_obj) {
    var classes = '',
      button = $('<button type="button">' + button_obj.label + '</button>')
    button.addClass(button_obj.classes)
    modal.find('.modal-footer').append(button)
  })

  // Add the close
  modal.find('.modal-footer').append(close)

  // Add modal to DOM
  $('#h-delegate').append(modal)

  loadModule('modal').then((module) => {
    // Show it
    modal
      .find('.modal-body')
      .html(msg)
      .end()
      .modal('show')
  })

  // Pass the modal back to the callback in case we have some trigger stuff we
  // want to implement
  if (callback && callback instanceof Function) {
    var has_more_buttons = 0 < Object.keys(more_buttons_obj).length
    if (!has_more_buttons) {
      // Default behavior, shows close and waits
      modal.on('hidden.bs.modal', function(e) {
        callback(modal)
      })
      if (timer) {
        setTimeout(function() {
          modal.modal('hide')
        }, timer)
      }
    } else {
      // Custom logic for more buttons, doesn't wait (yet) you have to
      // implement that
      callback(modal)
    }
  }
  return false
}

/**
 * @param response
 *            Object returned from server
 * @param enclosing_element
 *            on which we should attach this alert to
 * @param position
 *            append, html, before, after
 * @param status_class
 *            bootstrap color indicator
 * @param animation
 *            animate class
 * @returns
 */
export function h_show_inline_alert(config) {
  let { response, enclosing_element, position, status_class, animation } = config

  position = position || 'html'
  enclosing_element = $(enclosing_element)

  let animationClasses = ['animated']
  animationClasses.push((animation || 'shakeX').split(' '))
  animation = animationClasses.map((a) => `animate__${a}`)

  var decodeHtml = function(html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    is_error_object = response instanceof Error,
    status = is_error_object ? 0 : response[_STATUS]

  status_class = 'alert ' + (status_class ? status_class : status ? 'alert-success' : 'alert-danger')

  var msg = is_error_object ? response.toString() : decodeHtml(response[_MSG]),
    markup = '<div class="h-inline-alert ' + status_class + ' ' + animation.join(' ') + '">' + msg + '</div>'
  switch (position) {
    case 'html':
      enclosing_element.html(markup)
      break
    case 'before':
      enclosing_element.before(markup)
      break
    case 'after':
      enclosing_element.after(markup)
      break
    case 'append':
      enclosing_element.append(markup)
      break
    default:
      break
  }
}

export function rest_entry_point(args) {
  args = args || {}
  let {
    beforeSend, // a function to call before sending request
    always, // a function to call always
    method, // @see Rest::REST_CLASSES for route and method definitions
    cache, // whether to send along xhr key and ultimately cache the response, defaults to true
    route, //  @see Rest::REST_CLASSES for route definitions, ie. 'ui-component'
    request_data, // an object that makes up the query string to send with route
  } = args

  if (!route) {
    throw new Exception('Invalid route passed to rest_entry_point')
  }

  ;(beforeSend =
    beforeSend ||
    function() {
      return true
    }),
    (always =
      always ||
      function() {
        return true
      }),
    (request_data = request_data || {})

  const ajaxPromiseFunc = (resolve, reject) => {
    // Add the cache key to the URL (see default.vcl bereq.x-url = ... ) as well as the request header for easy varnish tracking
    let cacheKey = null
    if (cache !== false) {
      const md5 = require('md5')
      let cacheComponents = [_RESTURL, route, request_data]
      cacheKey = md5(JSON.stringify(cacheComponents))
      request_data[_INPUT_CACHE_KEY] = cacheKey
    }

    // Assemble the route url
    const url = _RESTURL + route + '/?' + httpBuildQuery(request_data)

    const request = $.ajax({
      url: url,
      contentType: 'application/json',
      method: method || 'GET',
      dataType: 'json',
      beforeSend: function(xhr) {
        if (cacheKey) {
          xhr.setRequestHeader('X-Cache-Key', cacheKey)
        }
        if (_NONCE) {
          xhr.setRequestHeader('X-WP-Nonce', _NONCE)
        }
        beforeSend(xhr)
      },
    })

    request
      .done((response, textStatus, jqXHR) => {
        if (response && response.data && jqXHR.status === 200) {
          if (!check_version_mismatch(jqXHR)) {
            reject({ code: _REST_VERSION_MISMATCH })
          }
          resolve(response.data)
        } else {
          switch (jqXHR.status) {
            case 204:
              resolve({ msg: 'No results found' })
              break
            default:
          }
        }
      })
      .fail((jqXHR, textStatus) => {
        let responseJSON = jqXHR.responseJSON || {},
          responseText = jqXHR.responseText || ''
        if (responseJSON && responseJSON.code) {
          reject(responseJSON)
        }
      })
      .always((response) => {
        h_console(`Loaded REST route: ${route} with response:`, response)
        always(response)
      })
  }

  // NOTE: you MUST provide a catch() to handle rest rejections individually
  return new Promise(ajaxPromiseFunc).catch((error) => {
    return Promise.reject(error)
  })
}

/**
 *   @param  Object responseJSON               Looks like this: { code: e.cause, message: e.message }
 *   @param  String args                Possible args: urlOverride, inline, inline_alert_parent, inline_alert_position
 */
export function handle_rest_rejections(responseJSON, args) {
  args = args || {}

  let { urlOverride, inline, inline_alert_parent, inline_alert_position, status_class, animation } = args

  urlOverride = urlOverride || window.location.href

  // Change presentation of error if cookie is present or not production
  var h_debug = false
  try {
    h_debug = document.cookie.split(';').filter((item) => item.trim().startsWith('_h_debug=')).length
  } catch (e) {}
  h_debug = h_debug || !is_production()

  const errorPresentation = {
    debug: JSON.stringify(responseJSON, null, 2), // admin message to present / log
    user: '', // Optional: prepended to below endpoing_message from server
    endpoint_message: responseJSON.msg || responseJSON.message || '',
    callback: null, // fn to call just before we exit out
    alert_callback: null, // fn to call after user is presented with alert
    alert_delay: null, // defaults to none, seconds if needed
    suppress: false, // set to suppress inline alert to user
    inline: inline, // Whether this should be an inline alert (vs a popup)
    error: new Error('REST rendering error, see responseJSON'), // set to an Error object to trigger admin email, to suppress, override to null below
  }

  switch (responseJSON.code) {
    case _REST_VERSION_MISMATCH:
    case _REST_NO_ROUTE:
      errorPresentation.user = 'Page will refresh in (10) seconds because we updated our software'
      errorPresentation.alert_callback = (modal) => {
        hardReload(urlOverride)
      }
      errorPresentation.alert_delay = 10000
      errorPresentation.error = null
      break
    case _REST_BAD_REQUEST:
      errorPresentation.user = 'Unexpected error: '
      break
    case _REST_RENDERING_ERROR:
    case _REST_FORBIDDEN:
    case _REST_INVALID_NONCE:
    case _REST_INVALID_PARAM:
    case _REST_MISSING_PARAM:
    case _REST_INTERNAL_SERVER_ERROR:
      errorPresentation.user = 'Error accessing the server'
      break
    case _REST_NO_DATA:
      // TODO: this is a signal for Infinite Scoll ( I think );
      break
    case _REST_GRECAPTCHA_FAILURE:
      break
    case _REST_AJAX_LOCKED:
      errorPresentation.suppress = true
      break
    default:
      errorPresentation.user = 'Session has become out of sync. Page will refresh in (10) seconds'
      errorPresentation.alert_callback = (modal) => {
        hardReload(urlOverride)
      }
      errorPresentation.alert_delay = 10000
  }

  const presentedMessage =
      errorPresentation.user +
      (h_debug ? ` [${responseJSON.code}]<pre class="text-left">${errorPresentation.debug}</pre>` : ''),
    callback = errorPresentation.callback || false,
    alert_callback = errorPresentation.alert_callback || false,
    timer = h_debug ? false : errorPresentation.alert_delay // no timers for debugging

  if (!errorPresentation.suppress) {
    if (errorPresentation.inline) {
      // Do inline stuff
      h_show_inline_alert({
        response: new Error([presentedMessage, errorPresentation.endpoint_message].join(' ')),
        enclosing_element: $(inline_alert_parent),
        position: inline_alert_position,
        status_class: status_class,
        animation: animation,
      })
      h_error(responseJSON, errorPresentation)
    } else {
      // msg, callback, more_buttons_obj, timer, alert_color
      h_alert({
        msg: presentedMessage,
        callback: alert_callback,
        timer: timer,
      })
    }
  }

  if (callback && callback instanceof Function) {
    callback()
  }

  if (errorPresentation.err && errorPresentation.err instanceof Error) {
    return h_error(err, responseJSON)
  }

  h_console(responseJSON)
}

export function h_render_component(config) {
  let { target_container, render, append, data } = config

  const paged = '<!-- Paged: ' + _CURRENT_PAGE + ' -->'
  const doc = traverse(render)
  if (append) {
    $(target_container)
      .append(paged)
      .append(doc.cloneNode(true))
      .append(paged)
      .append('\n\n')
  } else {
    $(target_container)
      .append(paged)
      .html(doc.cloneNode(true))
      .append(paged)
  }

  return Promise.resolve(data)
}

export function observeRendering(wrapper, callback) {
  // Select the node that will be observed for mutations
  const targetNode = $(wrapper).get(0)

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true }

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config)
}

function traverse(data) {
  if (!data) {
    return
  }

  const root = new DocumentFragment(),
    createNode = (parent, currentObj) => {
      const name = currentObj.localName || false,
        value = currentObj.value || '',
        attributes = currentObj.attributes || [],
        children = Object.keys(currentObj.children).length > 0 ? currentObj.children : false

      // Create the node and attach the parent
      let newNode = document.createElement(name)
      attributes.forEach((att) => {
        var value = is_object(att.value) ? JSON.stringify(att.value) : att.value
        newNode.setAttribute(att.localName, value)
      })
      newNode.innerHTML = value
      parent.appendChild(newNode)

      if (children) {
        children.forEach((child) => {
          createNode(newNode, child)
        })
      }
    }

  if (!Array.isArray(data)) {
    console.log(data)
  }
  data.forEach((node) => {
    createNode(root, node)
  })

  return root
}

export function h_push_state(url, rest_data) {
  if (!url) return

  let originalState

  // Quickview - hitting back triggers close on QV
  if (rest_data.callback && rest_data.callback === 'h_show_side_slide') {
    originalState = {
      eventType: 'click',
      selector: '.triggers-lazy-module',
      module: 'handleCloseSideSlide',
      url: window.location.href,
      namespace: 'quickview',
    }

    // Browse - hitting back triggers previous search
  } else if (
    rest_data.request_data &&
    rest_data.request_data.component &&
    rest_data.request_data.component.match(/BrowseResults/)
  ) {
    originalState = {
      eventType: 'click',
      selector: '.the-last-selector-triggered',
      namespace: 'browse',
      // When this is clicked in browse, somehow save the original ??
    }
    // Advanced Search
  } else if (
    rest_data.local_data &&
    rest_data.local_data.operation_class &&
    rest_data.local_data.operation_class.match(/OperationAdvancedSearch/)
  ) {
    originalState = {
      eventType: 'click',
      selector: '#closeAllSearch',
      namespace: 'advanced_search',
    }
  } else {
    originalState = {
      namespace: 'default',
    }
  }

  const url_new = url.match(/^\?/) ? new URL(url, window.location.origin + window.location.pathname) : new URL(url),
    url_existing = new URL(window.location.href),
    mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(url_existing.searchParams),
      ...Object.fromEntries(url_new.searchParams), // supercedes previous dupes
    }),
    mergedURL = new URL(
      '?' + (originalState.namespace === 'browse' ? url_new.searchParams : mergedSearchParams.toString()),
      url_new.origin + url_new.pathname
    ),
    advancedSearchMode = $('#collapseSearch.show').length > 0

  history.replaceState(
    { currentNamespace: originalState.namespace, originalState: originalState },
    '',
    mergedURL.toString()
  )

  if (originalState && originalState.namespace) {
    saveOriginalState(originalState)
  }
}

export function saveOriginalState(newState) {
  if (!newState.namespace) {
    throw new Error('Must provide a namespace in the state object')
  }
  let originalState
  let originalStateAttr = $('body').attr('data-original-state')
  if (originalStateAttr === undefined) {
    originalState = {}
  } else {
    originalState = JSON.parse(originalStateAttr)
  }
  // Don't add it twice
  if (!originalState[newState.namespace]) {
    originalState[newState.namespace] = newState
    $('body').attr('data-original-state', JSON.stringify(originalState))
    history.replaceState({}, '', newState.new_url)
  }
}

export function restoreOriginalState(namespace) {
  // Bring history back to normal
  let originalStateAttr = $('body').attr('data-original-state')
  if (originalStateAttr !== undefined) {
    let originalState = JSON.parse(originalStateAttr)
    if (originalState[namespace] && originalState[namespace].url) {
      history.replaceState({}, '', originalState[namespace].url)
      delete originalState[namespace]
      $('body').attr('data-original-state', JSON.stringify(originalState))
    }
  }
}

export function listenCookieChange(callback, interval = 1000) {
  let lastCookie = document.cookie
  setInterval(() => {
    let cookie = document.cookie
    if (cookie !== lastCookie) {
      try {
        callback(lastCookie, cookie)
      } finally {
        lastCookie = cookie
      }
    }
  }, interval)
}

/**
 * Recursively scrubs all null object values = useful for sending to analytics
 * @param  {[type]} obj [description]
 * @return {[type]}     [description]
 */
export function scrubObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
    if (typeof obj[propName] === 'object' && !Array.isArray(obj[propName])) {
      let deep_obj = scrubObject(obj[propName])
      delete obj[propName]
      obj = { ...obj, ...deep_obj }
    }
  }
  return obj
}

export function animateCSS() {
  const args = [...arguments]

  let node = args.shift(),
    animationTriggered = false

  node = $(node)

  let classes = []
  for (const arg of args) {
    if (Array.isArray(arg)) {
      classes.push(...arg)
    } else {
      var argOrArgs = arg.split(' ')
      classes.push(...argOrArgs)
    }
  }

  let animationNames = classes.map((arg) => arg.replace(/^animate__/, '')).filter(Boolean)
  animationNames.unshift('animated')

  // We create a Promise and return it
  const animationPromise = (resolve, reject) => {
    node.addClass(
      animationNames.map((anim) => {
        return 'animate__' + anim
      })
    )

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      animationTriggered = true
      event.stopPropagation()
      node.removeClass(function(index, classes) {
        return classes.split(' ').filter((c) => {
          if (c.match(/^animate__/)) return true
          var re = new RegExp('^@'),
            extraClasses = animationNames.filter((a) => a.match(re)).map((a) => a.replace(re, ''))
          return extraClasses.includes(c)
        })
      })
      return resolve(node)
    }

    // Fallback in case animation doesn't trigger, we resolve the promise
    setTimeout(() => {
      if (!animationTriggered) {
        return resolve(node)
      }
    }, 1000)

    node[0].addEventListener('animationend', handleAnimationEnd, { once: true })
  }
  return new Promise(animationPromise)
}

export function collectComponentStateRequestData(original_target, component_wrapper) {
  const request_data = {}

  // Specific to state attributes
  request_data[_INPUT_COMPONENT] = $(component_wrapper).attr('data-component-class')
  request_data[_INPUT_DATA_CLASS] = $(component_wrapper).attr('data-access-class')

  // query args to pass along, json
  if (original_target && original_target.attr('data-args')) {
    request_data[_INPUT_DATA_ARGS] = JSON.parse(original_target.attr('data-args'))
  }

  // arbitrary metadata to pass along, json
  request_data[_INPUT_DATA_META] = original_target.attr('data-meta')
    ? JSON.parse(original_target.attr('data-meta'))
    : {}

  return request_data
}

export async function addImageProcess(urls) {
  if (!Array.isArray(urls)) {
    urls = [urls]
  }

  if (!urls.length) return Promise.resolve()

  // Remove any previous preloads
  $('body link[as="image"]')
    .add('#preloadImages')
    .remove()

  const df = new DocumentFragment(),
    image_div = document.createElement('div')
  image_div.classList.add('d-none')
  image_div.id = 'preloadImages'

  // Adding preload is equivalent to the below promise approach
  // We add them to the DOM (d-none) so Chrome doesn't complain
  for (const url of [...new Set(urls)]) {
    let link = $(`<link rel="preload" href="${url}" as="image">`),
      img = new Image()
    df.appendChild(link[0])
    // Not adding the src yet
    image_div.appendChild(img)
  }

  // Append the images (hidden)
  df.appendChild(image_div)

  // Drop it all into the DOM
  document.body.append(df.cloneNode(true))

  const singleImgProm = (url, img) => {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve({ img: img, cached: false })
      img.onerror = reject
      img.src = url
      // This will always return true if src is not set yet
      if (img.complete) {
        resolve({ img: img, cached: true })
      }
    })
  }

  let start = Date.now(),
    indent = '    ',
    images = await Promise.all(
      $('#preloadImages > *')
        .get()
        .map(async (img_in_dom, i) => await singleImgProm(urls[i], img_in_dom))
    ),
    filenames = []

  for (let i = 0; i < images.length; i++) {
    const image = images[i],
      img = image.img,
      filename = img
        .getAttribute('src')
        .split('/')
        .slice()
        .pop(),
      cached = img.cached ? '[CACHED]' : '[FETCHED]'
    filenames.push(`${filename} ${cached}`)
  }

  h_console(
    `addImageProcess for \n${indent}${filenames.join(`\n${indent}`)}\ntook ${(Date.now() - start) / 1000} seconds`
  )

  return images
}

export async function preloadEnargeableImages() {
  var sources = $('.h-triggers-enlarge')
    .get()
    .map((s) => $(s).attr('data-full-image'))
  if (sources.length) {
    await addImageProcess(sources)
  }
}

export function prune(obj) {
  if (!obj) return {}
  const falsy = ['', ``, null, undefined, NaN]
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !falsy.includes(v)))
}

export function httpBuildQuery(pairsObj) {
  if (!pairsObj || Object.keys(pairsObj).length < 1) {
    return ''
  }
  var hbq = require('http-build-query'),
    qs = hbq(prune(pairsObj)) || ''
  // Sort them
  if (qs) {
    var sp = new URLSearchParams(qs)
    sp.sort()
    qs = sp.toString()
  }
  return qs || ''
}

export function loadLocalizedData() {
  let operation = 'UIAdminLocalData',
    route = local_obj.route_map[operation]['route'],
    method = local_obj.route_map[operation]['method'],
    request_data = {}

  request_data[_INPUT_PAGE] = 'insert'

  return rest_entry_point({
    route: route,
    request_data: request_data,
    method: method,
    cache: method == 'GET',
  })
    .then((body) => {
      local_obj.artists = body.artists
      local_obj.work_categories = body.work_categories
      local_obj.media = body.media
      return body
    })
    .catch((error) => {
      h_error(error)
    })
}

function check_version_mismatch(jqXHR) {
  const server_release = jqXHR.getResponseHeader('X-Release')
  if (server_release) {
    var c_exists = document.cookie.split(';').find((item) => item.trim().startsWith('_h_release='))
    if (c_exists) {
      const browser_release = c_exists.split('=')[1]
      return browser_release === server_release
    }
  }
  return false
}
