import {
  collectComponentStateRequestData,
  is_production,
  httpBuildQuery,
  handle_rest_rejections,
} from '@lib/globals.js'
import { componentStateDone } from '@lib/handlers/handleComponentState.js'

// Tracks scrolls, clone with JSON.parse(JSON.stringify(a))
export const scrollFactoryConfig = {
  container: null,
  scrollable: null,
  scroll: null,
  active: false,
  onice: false,
  cacheKeys: {},
}

export default class InfiniteScrollFactory {
  constructor() {
    if (!InfiniteScrollFactory.instance) {
      // Singleton instance
      InfiniteScrollFactory.instance = this

      window.InfiniteScrollFactory = this

      this.registeredScrolls = []

      // Server determines if ok to paginate, i.e. user lands on a ?paged=5
      if (_INFINITE_ENABLED) {
        // Set default srcroll for page
        // This is the macro container, not the scroll which is stored in an attr
        let scrollable = $('.scrollable')

        // Check the DOM for scrolls and create them
        let container = $(scrollable.attr('data-scroll-container')).get(0)

        // Create the default scroll config
        const scrollConfig = JSON.parse(JSON.stringify(scrollFactoryConfig))
        scrollConfig.container = container
        scrollConfig.scrollable = scrollable.get(0)
        scrollConfig.active = true

        // Create the scroll
        this.createScroll(scrollConfig)
      }
    }

    this.cacheKeyMap = new Map()

    return InfiniteScrollFactory.instance
  }

  getOptions() {
    // This varies depending on whether we are a button-driven load or scroll
    let scrollConfig = this.getScrollConfig(),
      container = scrollConfig.container,
      isButton = $(scrollConfig.scrollable).find('.load-more').length === 1

    // Define paginationPath here so you have access to this
    const paginationPath = () => {
        var request_data = this.getRequestData()
        request_data[_INPUT_CACHE_KEY] = this.calculateCacheKey()
        return _RESTURL + 'ui-component/?' + httpBuildQuery(request_data)
      },
      customHeaders = () => {
        return {
          method: 'GET',
          mode: 'cors',
          headers: {
            'X-Cache-Key': getFactoryInstance().calculateCacheKey(),
          },
        }
      }

    let scrollThreshold = this.calculateScrollThreshold(container)

    const defaultOptions = {
      path: paginationPath,
      responseBody: 'json',
      loadOnScroll: true,
      scrollThreshold: scrollThreshold,
      checkLastPage: true,
      append: false, // This must be handled custom by componentStateDone
      debug: true,
      history: false,
      fetchOptions: customHeaders,
    }

    // For pages where we prefer a scroll button, override default behavior
    if (isButton) {
      defaultOptions.scrollThreshold = false
      defaultOptions.button = '.load-more'
      defaultOptions.loadOnScroll = false
    }
    return defaultOptions
  }

  getRequestData() {
    const infScroll = this.getScroll(),
      domTargets = this.getDOMTargets()
    var request_data = collectComponentStateRequestData(domTargets.target, domTargets.component)
    request_data.data_meta[_INPUT_INFINITE] = true
    // Insert the proper page
    request_data.data_args[_INPUT_PAGED] = this.getNextPage() // because the initial is populated
    return request_data
  }

  getNextPage() {
    const infScroll = this.getScroll()
    return infScroll ? infScroll.loadCount + 2 : 2
  }

  calculateScrollThreshold(container) {
    container = $(container)
    const percentageOfContainer = 1.66
    const vh = Math.max(document.documentElement.clientHeight)
    let st = percentageOfContainer * vh
    h_console('Scroll threshold set to: ' + st)
    return st
  }

  calculateCacheKey() {
    const scrollConfig = this.getScrollConfig(),
      existingCacheKeys = scrollConfig.cacheKeys,
      nextPage = this.getNextPage()

    if (existingCacheKeys[nextPage]) {
      return existingCacheKeys[nextPage]
    }

    const route = 'ui-component',
      request_data = this.getRequestData(),
      cacheComponents = JSON.stringify([_RESTURL, route, request_data])

    const md5 = require('md5')
    const cacheKey = md5(cacheComponents)
    this.registeredScrolls.filter((s) => s.active === true).map((e) => (e.cacheKeys[nextPage] = cacheKey))
    h_console('Stored new cacheKey for page ' + nextPage, this.registeredScrolls)
    return cacheKey
  }

  // Creates a new scroll. If one (active) exists, unconditionally destroy it
  createScroll(scrollConfig) {
    let { container, scrollable, scroll, active } = scrollConfig,
      thiz = this

    const InfiniteScroll = require('infinite-scroll')

    // Destroy any active scrolls
    this.destroyScroll()

    this.registeredScrolls.push(scrollConfig)

    // Bail out if no navigation necessary
    if (this.getDOMTargets() === false) {
      this.registeredScrolls = []
      h_console('No domTargets, aborting infScroll')
      return
    }

    try {
      let infScroll = new InfiniteScroll(container, this.getOptions())
      // Add the listeners
      infScroll.on('load', this.onPageLoad)
      infScroll.on('error', this.onError)
      infScroll.on('scrollThreshold', this.onScrollThreshold)
      infScroll.on('last', this.onLast)
      infScroll.on('request', () => {
        this.showElipses(true)
      })

      // This will update the registered scroll directly
      scrollConfig.scroll = infScroll

      h_console('Scroll created', this.registeredScrolls)
    } catch (e) {
      h_error(e)
    }
  }

  // Retrieves and instance of the scroll by passing container
  // false, if none exists
  getScroll(container) {
    let scrollConfig = this.getScrollConfig(container)
    return scrollConfig ? scrollConfig.scroll : false
  }

  // Gets the factory scroll config, which has access to everything needed
  // Can pass container, scrollable or leave empty to return active
  getScrollConfig(key) {
    let config = this.registeredScrolls.find((s) => s.container === key || s.scrollable === key || s.active === true)
    if (!config) {
      return false
    }
    return config
  }

  // Places the active scroll on ice, removes listeners
  iceScroll() {
    this.registeredScrolls
      .filter((s) => s.active === true)
      .map((s) => {
        s.onice = true
        s.active = false
        // remove event listener
        s.scroll.off('load', this.onPageLoad)
        s.scroll.off('scrollThreshold', this.onScrollThreshold)
        s.scroll.options.scrollThreshold = false
        s.scroll.options.loadOnScroll = false
        h_console('Scroll suspended', this.registeredScrolls)
      })
  }

  // Activate the on-ice scroll
  // Careful: there might be two on ice
  activateScroll(destroyExisting) {
    if (destroyExisting) {
      this.destroyScroll()
    }
    this.registeredScrolls
      .filter((s) => s.onice === true)
      .slice(-1) // take the last one
      .map((s) => {
        s.onice = false
        s.active = true
        // add event listener
        s.scroll.on('load', this.onPageLoad)
        s.scroll.on('scrollThreshold', this.onScrollThreshold)
        s.scroll.options.scrollThreshold = this.calculateScrollThreshold(s.container)
        s.scroll.options.loadOnScroll = true
        h_console('Scroll activated', this.registeredScrolls)
      })
  }

  destroyScroll() {
    let index = this.registeredScrolls.findIndex((s) => s.active === true)
    if (index > -1) {
      scroll = this.registeredScrolls[index].scroll
      scroll.destroy()
      this.registeredScrolls.splice(index, 1)
      h_console('Scroll destroyed', this.registeredScrolls)
    }
  }

  /**
   *   If you choose to loadNextPage() manually based off triggered scroll
   *   you would do that here, but prefer to try the built-in func first.
   */
  onScrollThreshold() {
    h_console('onScrollThreshold() triggered')
  }

  // Triggered when the next page has been successfully loaded, but not yet added to the container.
  onPageLoad(body, path, response) {
    h_console('onPageLoad() triggered', body, path, response)
    const loadPromise = new Promise((resolve, reject) => {
      if (response.ok) {
        resolve(body.data)
      } else {
        reject(response)
      }
    })
    getFactoryInstance().handleLoad(loadPromise)
  }

  handleLoad(loaded) {
    loaded
      .then((body) => {
        if (body.status === 204) {
          let infScroll = this.getScroll()
          infScroll.lastPageReached(body)
          return body
        }
        const domTargets = this.getDOMTargets()
        const data = {
          body: body,
          original_target: domTargets.target,
          component_wrapper: domTargets.component,
          grid_wrapper: this.getScrollConfig()['container'],
          callback: domTargets.callback,
          append: true,
        }
        $('body').addClass('infinite') // Indicates to componentStateDone that this is appends to the wrapper, not the base component
        componentStateDone(data)
        return body
      })
      .catch((error) => {
        // I think "error" takes care of this anyway
        h_error(error)
      })
      .finally(() => {
        $('body').removeClass('infinite')
        this.showElipses(false)
      })
  }

  // Triggered when the last page has been loaded. last is triggered with several conditions.
  // Among them, a 204 status
  // @see https://infinite-scroll.com/events.html#last
  onLast(body, path) {
    h_console('onLast() triggered', body, path)
    const thiz = getFactoryInstance()
    thiz.showElipses(false)
    thiz.showStatus(true)
    thiz.iceScroll()
  }

  onError(error, path, response) {
    h_console('onError() triggered', error, path, response)
    let responseJSON = response.responseJSON || { msg: error }
    handle_rest_rejections(responseJSON, { inline: false })
    getFactoryInstance().showElipses(false)
  }

  getDOMTargets() {
    const scrollConfig = this.getScrollConfig()
    if (!scrollConfig) {
      return false
    }
    const base_path_target = $(scrollConfig.scrollable).find('nav > .nav-links > a.h-triggers-state-change:first-child')

    // No nav, discontinue infinite processing
    if (base_path_target.length < 1) {
      return false
    }

    const target = $(base_path_target[0]),
      component = target.attr('data-target')

    return {
      target: target,
      component: component,
      callback: null, // I see no callbacks needed
    }
  }

  showElipses(show) {
    const scrollConfig = this.getScrollConfig()
    if (!scrollConfig) {
      return
    }
    let scrollable = $(scrollConfig.scrollable)
      .find('.page-load-status > .infinite-scroll-request')
      .toggleClass('d-none', !show)
  }

  showStatus(show) {
    const scrollConfig = this.getScrollConfig()
    if (!scrollConfig) {
      return
    }
    let scrollable = $(scrollConfig.scrollable)
      .find('.page-load-status > .infinite-scroll-error')
      .toggleClass('d-none', !show)
  }
}

// Helper to get instance within InfiniteScroll context
function getFactoryInstance() {
  return new InfiniteScrollFactory()
}

// Use this to push a new scroll to the DOM, placing the existing default on ice
// It reacts to a manual event, hence the original_target and the scrollable already must be in DOM
// i.e 'Advanced Search' or 'Browse' first attempts
// If an existing scroll is on ice, it destroys and recreates it.
export function pushNewScroll(rendering_target) {
  let scrollConfig = JSON.parse(JSON.stringify(scrollFactoryConfig)),
    scrollable_component_selector =
      '#' +
      $(rendering_target)
        .find('.scrollable')
        .attr('id'),
    scrollable_component = $(scrollable_component_selector)
  if (scrollable_component.length) {
    let scroll_container_selector = scrollable_component.attr('data-scroll-container'),
      container = scrollable_component.find(scroll_container_selector).get(0),
      scrollFactory = new InfiniteScrollFactory()
    scrollConfig.scrollable = scrollable_component.get(0)
    scrollConfig.container = container
    scrollConfig.active = true
    scrollFactory.createScroll(scrollConfig)
  }
}

// const instance = new InfiniteScrollFactory();

// window.InfiniteScrollFactory = instance;

// export default instance;
