import _debounce from '@node/lodash.debounce'

export default function (scrollElement = window, positionOrElement = 0, { block, behavior } = {}) {
  let debounceIt = (fn) => _debounce(fn, 100, { leading: false, trailing: true }),
    debounced,
    handleScroll
  return new Promise((resolve) => {
    const doScroll = () => {
      if ([null, true, false, undefined, '', ``, NaN].includes(positionOrElement)) {
        positionOrElement = 0
      }
      if (positionOrElement instanceof HTMLElement) {
        positionOrElement.scrollIntoView({
          block: block || 'center',
          behavior: behavior || 'smooth',
        })
      } else if (!isNaN(positionOrElement)) {
        scrollElement.scrollTo({
          top: positionOrElement,
          behavior: 'smooth',
        })
      } else {
        reject('Invalid scroll config')
      }
    }

    document.body.classList.add('scrolling')

    let done = (val) => {
      document.body.classList.remove('scrolling')
      scrollElement.removeEventListener('scroll', debounced)
      resolve(val)
    }
    if (Math.abs(scrollElement.scrollY - Number(positionOrElement)) < 10) {
      done('No scroll Necessary')
    }

    handleScroll = (e) => {
      typeof debounced === 'function' && debounced.cancel()
      debounced = typeof handleScroll === 'function' && debounceIt(handleScroll)
      done('Scroll complete')
    }

    debounced = debounceIt(handleScroll)

    scrollElement.addEventListener('scroll', debounced)

    doScroll()
  })
}
