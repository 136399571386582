/** Entry point for all scss **/
import '@src/index.scss'
import { h_init } from '@src/routes/common.js'
import { listeners } from '@lib/handlers/listeners.js'
import { h_console, h_error, is_production, h_alert } from '@lib/globals.js'
import '@node/bootstrap/js/dist/collapse.js'
import '@node/bootstrap/js/dist/dropdown.js'
import '@node/bootstrap/js/dist/tooltip'

const $ = (jQuery = require('jquery'))
window.$ = $
window.is_production = is_production
window.h_console = h_console
window.h_error = h_error
window.h_alert = h_alert
window.esc_html = (str) =>
  $('<span>')
    .text(str)
    .html()

const routes = [
  'artists',
  'about',
  'browse',
  'cart',
  'checkout',
  'contact',
  'default',
  'home',
  'insert',
  'inquire',
  'presentation',
  'services',
  'artist',
  'exhibition',
  'product',
  'wishlist',
]

function getModuleName() {
  const bodyClasses = $('body')
      .attr('class')
      .split(' ')
      .map((theClass) => theClass.replace('-js', '')),
    route = bodyClasses.filter((value) => routes.includes(value))
  if (Array.from(new Set(route)).length > 1) {
    throw 'Invalid route config'
  }
  return route[0] || 'default'
}

jQuery(document).ready(() =>
  (async function init(init_common) {
    try {
      await init_common()
    } catch (e) {
      h_error(e.message)
    }

    const module_name = getModuleName() + '.js'
    import(
      /* webpackChunkName: "[request]" */
      `./routes/${module_name}`
    )
      .then((module) => {
        const init = module.default
        h_console('loaded ' + module_name)
        init()
        listeners()
      })
      .catch((error) => {
        throw error
      })
  })(h_init)
)
