import { control_submit } from '@src/routes/insert'

/* Import TinyMCE */
import tinymce from 'tinymce'

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default'

/* A theme is also required */
import 'tinymce/themes/silver'

/* Import the skin Moved to index.scss */
// import 'tinymce/skins/ui/oxide/skin.css'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* Import content css */

// No, don't, it overrides base styles unnecessarily

// import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
// import contentCss from 'tinymce/skins/content/default/content.css';

/* Initialize TinyMCE */
export function render() {
  tinymce.init({
    selector: '.tinymce-enabled',
    plugins: 'advlist code emoticons link lists table paste',
    toolbar: 'bold italic | bullist numlist | link emoticons',
    paste_preprocess: preprocess,
    skin: false,
    height: 460,
    content_css: false,
    init_instance_callback: function(editor) {
      editor.on('Change', function(e) {
        let textarea = $(`#${this.id}`),
          isDirty = this.isDirty(),
          form = textarea.parents('form')
        textarea.addClass('filtered-input dirty').removeClass('clean')
        control_submit(form)
      })
    },
    content_style:
      "@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200..500&display=swap'); body { font-family: 'Jost', sans-serif; font-size: 1.2rem; font-weight: 300; line-height: 1.75; margin: 1.2rem;}",
  })
}

function preprocess(plugin, args) {
  const disallowed = [
    '!doctype',
    'a',
    'abbr',
    'acronym',
    'address',
    'applet',
    'area',
    'article',
    'aside',
    'audio',
    'base',
    'basefont',
    'bb',
    'bdo',
    'big',
    'blockquote',
    'body',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'command',
    'datagrid',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'dir',
    'div',
    'dl',
    'dt',
    'embed',
    'eventsource',
    'fieldset',
    'figcaption',
    'figure',
    'font',
    'footer',
    'form',
    'frame',
    'frameset',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'h1',
    'head',
    'header',
    'hgroup',
    'hr',
    'html',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'isindex',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'map',
    'mark',
    'menu',
    'meta',
    'meter',
    'nav',
    'noframes',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'style',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'tt',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ]

  let re = new RegExp('(</|<)(' + disallowed.join('|') + ')(>|\\s+.*?>)', 'gm')
  args.content = args.content
    .replace(re, '')
    .replace(/[\u2018\u2019]/g, "'")
    .replace(/[\u201C\u201D]/g, '"')
}
