import { rest_entry_point, handle_rest_rejections, saveOriginalState, restoreOriginalState } from '@lib/globals'
import { AutocompleteImpl } from '@lib/AutocompleteImpl.js'
const Awesomplete = require('awesomplete')
import InfiniteScrollFactory from '@lib/InfiniteScrollFactory'
import noUiSlider from 'nouislider'
import _debounce from '@node/lodash.debounce'

class AutocompleteEngine {
  constructor(inputTrigger, awesomplete_obj) {
    this.activatedInput = inputTrigger
    this.awesomplete = awesomplete_obj
    this.all_filter_toggles = $('#collapseSearch .filter-by')
    this.mainSearch = $('#mainSearch')
    this.primary_toggle = $('#collapseSearch')
    this.dropdown = $('.dropdown')
    this.dropdown_btn_trigger = $('#advancedFilters')
    this.search_btn = $('.search-filters')
    this.size_metric_radio = $('input.size-metric-radio')
    this.no_results = $(inputTrigger)
      .parents('.search-form')
      .find('.no-results')

    this.init_nouisliders()
    this.listeners()
  }

  init_nouisliders() {
    // Set up sliders
    var sliderWidth = document.getElementById('noUiSliderWidth'),
      sliderHeight = document.getElementById('noUiSliderHeight')

    noUiSlider.create(sliderWidth, {
      start: [30, 60],
      connect: true,
      step: 1,
      tooltips: this.tooltip_format(),
      range: {
        min: 1,
        max: local_obj.constants.max_sizes.max_width,
      },
    })
    noUiSlider.create(sliderHeight, {
      start: [30, 60],
      connect: true,
      step: 1,
      tooltips: this.tooltip_format(),
      orientation: 'vertical',
      range: {
        min: 1,
        max: local_obj.constants.max_sizes.max_height,
      },
    })

    this.sliderWidth = sliderWidth
    this.sliderHeight = sliderHeight
  }

  tooltip_format() {
    let thiz = this
    return {
      // 'to' the formatted value. Receives a number.
      to: function(value) {
        return Math.round(value) + (selectedMetric() == 'inches' ? '"' : 'cm')
      },
      // 'from' the formatted value.
      // Receives a string, should return a number.
      from: function(value) {
        return Number(value.replace(/(\"|cm)/, ''))
      },
    }
  }

  get_data_for_autocomplete() {
    var the_input = $(this.activatedInput),
      search = the_input.val(),
      current_count = search.length,
      min_chars = 3,
      data = {},
      awesomplete_obj = this.awesomplete,
      thiz = this

    if (current_count >= min_chars) {
      this.no_results.addClass('d-none')

      rest_entry_point({
        route: 'ui-search-autocomplete',
        request_data: { search_term: search },
      })
        .then((body) => {
          if (body.length) {
            awesomplete_obj.list = body
            awesomplete_obj.evaluate()
            thiz.no_results.addClass('d-none')
          } else {
            thiz.no_results.removeClass('d-none')
          }
        })
        .catch((responseJSON) => {
          handle_rest_rejections(responseJSON, {
            inline: true,
            inline_alert_parent: $('#collapseSearch > .search-form'),
            inline_alert_position: 'after',
          })
        })
    }
  }

  listeners() {
    var thiz = this,
      boundKeyUp = (e) => this.handle_key_up(this),
      debouncedKeyUp = _debounce(boundKeyUp, 500, { leading: false, trailing: true })
    this.dropdown.on('hidden.bs.dropdown', this, this.handle_dropdown_items)
    this.primary_toggle.on('show.bs.collapse', this, this.handle_show_primary_toggle)
    this.primary_toggle.on('shown.bs.collapse', this, this.handle_shown_primary_toggle)
    this.primary_toggle.on('hidden.bs.collapse', this, this.handle_hidden_primary_toggle)
    $(document)
      .on('keyup', '#mainSearch', debouncedKeyUp)
      .on('click', '#closeAllSearch', this, this.handle_close_all_search)
      .on('click', '.close-search-filter', this, this.handle_close_individual_search_filter)
      .on('change', 'input.size-metric-radio', this, this.handle_change_size_metric)
    this.all_filter_toggles.get().map((toggle) => {
      $(toggle).on('show.bs.collapse', this, thiz.handle_show_individual_search_filter)
      $(toggle).on('shown.bs.collapse', this, thiz.handle_shown_individual_search_filter)
      $(toggle).on('hidden.bs.collapse', this, thiz.handle_hidden_individual_search_filter)
    })
    this.sliderWidth.noUiSlider.on('update', this.handle_slider_update)
    this.sliderWidth.noUiSlider.on('end', () => {
      $('.axis-label-w').css({ opacity: 1 })
    })
    this.sliderWidth.noUiSlider.on('slide', () => {
      $('.axis-label-w').css({ opacity: 0 })
    })
    this.sliderHeight.noUiSlider.on('update', this.handle_slider_update)
    this.sliderHeight.noUiSlider.on('end', () => {
      $('.axis-label-h').css({ opacity: 1 })
    })
    this.sliderHeight.noUiSlider.on('slide', () => {
      $('.axis-label-h').css({ opacity: 0 })
    })
  }

  handle_slider_update(values, handle, unencoded, tap, positions, noUiSlider) {
    // values: Current slider values (array);
    // handle: Handle that caused the event (number);
    // unencoded: Slider values without formatting (array);
    // tap: Event was caused by the user tapping the slider (boolean);
    // positions: Left offset of the handles (array);
    // noUiSlider: slider public Api (noUiSlider);
    let widths,
      heights,
      colors = ['#ff9800bf', '#aedbb1'],
      upperThreshold = (dimension) => {
        return dimension == 'width'
          ? adjustForMetric(local_obj.constants.max_sizes.max_width, 'i')
          : adjustForMetric(local_obj.constants.max_sizes.max_height, 'i')
      },
      calculateBoxPercentage = (val, dimension) => {
        let max = upperThreshold(dimension)
        return Math.round(100 * (val / max))
      }
    var colorIndex = 0

    // Input links
    let wmin = $('#formControlMinWidth'),
      wmax = $('#formControlMaxWidth'),
      hmin = $('#formControlMinHeight'),
      hmax = $('#formControlMaxHeight'),
      input_link = {
        width: [wmin, wmax],
        height: [hmin, hmax],
      }

    $('.canvas').empty()
    if ($(this.target).is('#noUiSliderWidth')) {
      heights = $('#noUiSliderHeight')[0]
        .noUiSlider.get()
        .map((val) => Math.round(val))
      widths = values.map((val) => Math.round(val))
    } else {
      widths = $('#noUiSliderWidth')[0]
        .noUiSlider.get()
        .map((val) => Math.round(val))
      heights = values.map((val) => Math.round(val))
    }
    widths.forEach((width, iWidth) => {
      input_link.width[iWidth].val(Math.round(width))
      heights.forEach((height, iHeight) => {
        input_link.height[iHeight].val(Math.round(height))
        if ((iWidth === 0 && iHeight === 0) || (iWidth === 1 && iHeight === 1)) {
          let box = $('<div>'),
            metric_label = selectedMetric() == 'inches' ? '"' : 'cm',
            label =
              (iWidth === 0 ? `Min: ${heights[0]} x ${widths[0]}` : `Max: ${heights[1]} x ${widths[1]}`) + metric_label
          box
            .addClass('box')
            .html(label)
            .css({
              position: 'absolute',
              width: calculateBoxPercentage(width, 'width') + '%',
              height: calculateBoxPercentage(height, 'height') + '%',
              background: colors[colorIndex++],
              'z-index': iWidth === 0 ? 1 : 'unset',
            })
          if (iWidth === 1) {
            let xOffset = (100 - calculateBoxPercentage(width, 'width')) / 2,
              yOffset = (100 - calculateBoxPercentage(height, 'height')) / 2
            $('.canvas').css({
              transform: `translate(${xOffset}%, ${yOffset}%)`,
            })
          }
          $('.canvas').append(box)
        }
      })
    })
  }

  clear_input(target) {
    var target_element = $(target)
    switch (target_element.attr('id')) {
      case 'collapseFilterByCategory':
      case 'collapseFilterByType':
        target_element.find('input').prop('checked', false)
        break
      case 'collapseFilterByYear':
        target_element.find('input').val('')
        break
      case 'collapseFilterBySize':
        this.sliderWidth.noUiSlider.set([30, 60].map((v) => adjustForMetric(v, 'i')))
        this.sliderHeight.noUiSlider.set([30, 60].map((v) => adjustForMetric(v, 'i')))
        break
      default:
    }
  }

  blur_content(blur) {
    $('.h-template-content').toggleClass('blur', blur)
  }

  handle_key_up(thiz) {
    if (
      $(thiz.activatedInput)
        .val()
        .trim().length
    ) {
      thiz.get_data_for_autocomplete()
    } else {
      thiz.no_results.addClass('d-none')
    }
  }

  handle_dropdown_items(e) {
    var thiz = e.data,
      all_collapsed = true,
      dropdown = thiz.dropdown
    dropdown.find('.dropdown-item').each(function(idx, item) {
      $(item).removeClass('d-none')
      var expanded = $(item).attr('aria-expanded') == 'true'
      if (expanded) {
        all_collapsed = false
        $(item).addClass('d-none')
      }
    })
    var buttonText = thiz.dropdown_btn_trigger.find('span')
    if (all_collapsed) {
      thiz.dropdown_btn_trigger.removeClass('btn-success').addClass('btn-outline-primary')
      buttonText.html('Advanced Filters')
      thiz.search_btn.addClass('d-none').prop('disabled', true)
    } else {
      thiz.dropdown_btn_trigger.removeClass('btn-outline-primary').addClass('btn-success')
      buttonText.html('Filter Further')
    }
  }

  handle_show_primary_toggle(e) {
    var thiz = e.data
    thiz.blur_content(true)
    $('#nav-scroll')
      .addClass('nav-scroll')
      .removeAttr('id')
    $('#grid-scroll')
      .addClass('grid-scroll')
      .removeAttr('id')
  }

  handle_shown_primary_toggle(e) {
    // Place existing scroll on ice
    let infinite = new InfiniteScrollFactory()
    infinite.iceScroll()

    // Keep track of original history state
    let state = {
      url: window.location.href,
      selector: '#closeAllSearch',
      eventType: 'click',
      namespace: 'advanced_search',
      new_url: '/works/',
    }
    saveOriginalState(state)

    var thiz = e.data
    thiz.mainSearch.focus()
  }

  handle_hidden_primary_toggle(e) {
    var thiz = e.data
    $('.nav-scroll')
      .attr('id', 'nav-scroll')
      .removeClass('nav-scroll')
    $('.grid-scroll')
      .attr('id', 'grid-scroll')
      .removeClass('grid-scroll')

    thiz.blur_content(thiz.primary_toggle.hasClass('show'))
    thiz.mainSearch.val('')
  }

  handle_close_all_search(e) {
    var thiz = e.data
    thiz.all_filter_toggles.each(function(idx, filter_toggle) {
      $(filter_toggle)
        .find('input')
        .addClass('disabled')
      $(filter_toggle).collapse('hide')
      thiz.clear_input(filter_toggle)
    })

    // activate the on ice scroll, forcing destroy the current active
    let infinite = new InfiniteScrollFactory()
    infinite.activateScroll(true)

    // revert to original state
    restoreOriginalState('advanced_search')

    // Clean out the results and feedback
    $('#searchFeedback,#searchResultsAS').empty()

    thiz.primary_toggle.collapse('hide')
  }

  handle_close_individual_search_filter(e) {
    var thiz = e.data
    var target = $(e.currentTarget).attr('data-target')
    $(target).collapse('hide')
    $(target)
      .find('input')
      .addClass('disabled')
  }

  handle_show_individual_search_filter(e) {
    var thiz = e.data

    // all_filter_toggles.not( $(this) ).collapse('hide'); uncomment for accordion style

    // supposed to be the filter toggle
    $(e.target)
      .find('input')
      .removeClass('disabled')
  }

  handle_shown_individual_search_filter(e) {
    var thiz = e.data
    thiz.mainSearch.not(':disabled').attr('disabled', true)
    thiz.search_btn.removeClass('d-none').prop('disabled', false)
  }

  handle_hidden_individual_search_filter(e) {
    var thiz = e.data
    thiz.mainSearch.removeAttr('disabled')
    thiz.handle_dropdown_items(e)
    thiz.clear_input(this)
    // supposed to be the filter toggle
    $(e.target).addClass('disabled')
  }

  handle_change_size_metric(e) {
    var thiz = e.data,
      currentWidths = thiz.sliderWidth.noUiSlider.get(),
      currentHeights = thiz.sliderHeight.noUiSlider.get()
    thiz.sliderWidth.noUiSlider.updateOptions({
      range: {
        min: 1,
        max: adjustForMetric(local_obj.constants.max_sizes.max_width, 'i'),
      },
      tooltips: thiz.tooltip_format(),
    })
    thiz.sliderHeight.noUiSlider.updateOptions({
      range: {
        min: 1,
        max: adjustForMetric(local_obj.constants.max_sizes.max_height, 'i'),
      },
      tooltips: thiz.tooltip_format(),
    })
    let oldMetric = selectedMetric() == 'inches' ? 'c' : 'i'
    thiz.sliderWidth.noUiSlider.set(currentWidths.map((val) => adjustForMetric(val, oldMetric)))
    thiz.sliderHeight.noUiSlider.set(currentHeights.map((val) => adjustForMetric(val, oldMetric)))
  }
}

function adjustForMetric(val, metric_of_value) {
  if (selectedMetric() == 'inches') {
    return Math.round(metric_of_value == 'i' ? val : Math.round((val / 2.54) * 10) / 10)
  } else {
    return Math.round(metric_of_value == 'i' ? Math.round(val * 2.54 * 10) / 10 : val)
  }
}

function selectedMetric() {
  return $('[name=radioFilterSizeMetric]:checked').val()
}

export default function(triggering_evt, original_event, original_target) {
  $('body').toggleClass('search-progress', false)
  var mainSearch = $('#mainSearch'),
    myInput = mainSearch[0],
    autoOptions = new AutocompleteImpl(myInput),
    awesomplete = new Awesomplete(myInput, autoOptions.getOptions())

  var engine = new AutocompleteEngine(myInput, awesomplete)
}
